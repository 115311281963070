/**
 * Copyright 2020 Telekom Multimedia Solutions
 *
 * Class providing tracking features.
 */
const SELECTORS = {
    mainNavigationItem: '.main-navigation li:not(.has-submenu) > a',
    footerNavigationColumn: '.footer-navigation .footer-columns li',
    footerNavigationTitle: 'h4',
    footerNavigationItem: '.footer-navigation ul li a',
    modalLoginItem: '.modal-window.login > div[data-step]',
    modalLoginItemButton: 'input[type="submit"]',
    links: 'a[data-tracking], a[data-tracking-rewrite], input[data-tracking], button[data-tracking], button[data-tracking-rewrite]',
};
const CATEGORIES = {
    main: 'Main Navigation',
    footer: 'Footer Navigation',
    accordion: 'Accordion Klick',
    collapsible: 'ToggleContent Klick',
    video: 'Video Klick',
    customerTableSwitch: 'Toggle',
    internalSearch: 'Interne Suche',
    faq: 'FAQ',
};
/** Class providing tracking features. */
class Tracking {
    /**
     * Initialize tracking by:
     * 1st adding tracking JSON data to main navigation entries
     * 2nd adding tracking JSON data to footer navigation entries
     * 3rd adding 'click' event listener to all link and input elements
     */
    init() {
        this.prepareMainNavigation();
        this.prepareFooterNavigation();
        this.initTracking();
    }

    /**
     * Gets all main-navigation items (aka links) that are not part of a sub-menu and add tracking data as JSON
     * to the data attribute ('data-tracking').
     */
    prepareMainNavigation() {
        Array.from(document.querySelectorAll(SELECTORS.mainNavigationItem)).forEach((element) => {
            this.prepareNavigationItem(element, CATEGORIES.main, 'clickMainNav');
        });
    }

    /**
     * Gets all footer-navigation items (aka links) and add tracking data as JSON to the data attribute ('data-tracking').
     */
    prepareFooterNavigation() {
        Array.from(document.querySelectorAll(SELECTORS.footerNavigationItem)).forEach((element) => {
            this.prepareNavigationItem(element, CATEGORIES.footer, 'clickFooterNav');
        });
    }

    /**
     * Sets tracking information for given navigation element.
     *
     * @param element {Element} - navigation item
     * @param categoryName {String} - category to set
     * @param eventName {String} - event to set
     */
    prepareNavigationItem(element, categoryName, eventName) {
        if (element.attributes !== undefined && !element.hasAttribute('data-tracking')
            && element.hasAttribute('href')) {
            const href = element.getAttribute('href').trim();
            const data = {};
            data.category = categoryName;
            data.action = Tracking.getLinkTextWithoutScreenReaderText(element);
            data.label = href;
            data.event = eventName;
            this.setData(element, data);
        }
    }

    /**
     * Adds 'click' event listener to all link and input elements
     */
    initTracking() {
        Array.from(document.querySelectorAll(SELECTORS.links)).forEach((element) => {
            element.addEventListener('click', this.trackElement.bind(this));
        });
    }

    /**
     * Tracks event by pushing the given data to the dataLayer object
     *
     * @param data {string} - tracking data to push
     */
    static trackEvent(data) {
        let jsonData = data;
        if (jsonData === '' || jsonData === false) {
            return;
        }
        if (typeof data === 'string' && data.indexOf("'") >= 0) {
            jsonData = data.replace(/'/gi, '"');
        }
        if (typeof data === 'string') {
            jsonData = JSON.parse(jsonData);
        }
        if (typeof window.dataLayer !== 'undefined') {
            window.dataLayer.push(jsonData);
        }
    }

    /**
     * Returns tracking information from given element
     *
     * @param element {Element} - to get tracking data of
     * @returns {string|boolean} - tracking information as string otherwise false
     */
    getData(element) {
        if (element.hasAttribute('data-tracking')) {
            return element.getAttribute('data-tracking');
        }
        if (element.hasAttribute('data-tracking-rewrite')) {
            return element.getAttribute('data-tracking-rewrite');
        }
        return false;
    }

    /**
     * Adds given tracking data to given element.
     *
     * @param element {Element} - element to add tracking attribute to
     * @param data {Object} - object containing tracking information
     */
    setData(element, data) {
        if (typeof data === 'object') {
            data = JSON.stringify(data);
        }
        element.setAttribute('data-tracking', data);
    }

    /**
     * Extracts tracking JSON from given event target and trigger event tracking
     *
     * @param event {Event} - recently triggered event
     */
    trackElement(event) {
        const element = event.currentTarget;
        const data = this.getData(element);
        Tracking.trackEvent(data);
        const subMenu = element.closest('div.submenu');
        if (subMenu !== null) {
            // hack... do not trigger accordion close event after link (within submenu) was clicked
            event.stopPropagation();
        }
    }

    static jsonConform(str) {
        if (typeof str === 'string') {
            str = str.replace(/('|")/g, '');
        }
        return str;
    }

    /**
     * Tracks (given) navigation element click.
     *
     * @param element {Element} - element to track
     */
    static trackNavigationEntry(element) {
        let action = 'open';
        // Hint: do not remove leading space here: ' active' it's possible the element contains class 'is-active' as well
        if (element.hasAttribute('class') && element.getAttribute('class').includes(' active')) {
            action = 'close';
        }
        const data = {};
        data.category = CATEGORIES.accordion;
        data.action = action;
        data.label = this.jsonConform(Tracking.getLinkTextWithoutScreenReaderText(element.querySelector('a')));
        data.event = 'accordionClick';
        this.trackEvent(JSON.stringify(data));
    }

    /**
     * Tracks the submitting of a step-by-step form
     * @param element the form
     */
    static trackStepByStepFormSubmit(element, category) {
        let label = this.getLabelForStepByStepTracking(element);
        label += ' - Absenden';
        const data = {};
        data.event = 'generic';
        data.category = category;
        data.label = label;
        data.value = '-';
        let action = ' - Kundendaten';
        const step = $(element).find('.cmp-step-by-step__step');
        if (step.length > 0) {
            const text = step.text().replace(/\svon\s\d$/, '');
            action = text + action;
        }
        data.action = this.jsonConform(action);
        this.trackEvent(JSON.stringify(data));
    }

    /**
     * Delivers the label from previous steps for step-by-step tracking
     * @param element the current selected element
     * @returns {string} the label for tracking
     */
    static getLabelForStepByStepTracking(element) {
        const iconSelections = $(element).closest('form').find('.cmp-form-options--icon-selection');
        let label = '';
        iconSelections.each((idx, iconSelection) => {
            if (!$(iconSelection).is(':disabled')) {
                const selectedIcon = $(iconSelection).find('.cmp-form-options__checked').find('.cmp-form-options__field-description').text().trim();
                if (selectedIcon) {
                    label += `${selectedIcon} - `;
                }
            }
        });
        // remove last hyphen
        label = label.substring(0, label.length - 3);
        return label;
    }

    /**
     * Tracks the click on an icon selection of a step-by-step form
     * @param element the clicked element
     */
    static trackStepByStepFormIconSelectionClick(element, category) {
        const label = this.getLabelForStepByStepTracking(element);
        const data = {};
        data.event = 'generic';
        data.category = category;
        data.label = label;
        data.value = '-';
        const legend = $(element).closest('.cmp-form-options').find('legend').text()
            .trim();
        let action = ` - ${legend}`;
        const step = $(element).closest('.cmp-step-by-step').find('.cmp-step-by-step__step');
        if (step.length > 0) {
            const text = step.text().replace(/\svon\s\d$/, '');
            action = text + action;
        }
        data.action = this.jsonConform(action);
        this.trackEvent(JSON.stringify(data));
    }

    static trackStepByStepFormStepClick(element, category) {
        const data = {};
        data.event = 'generic';
        data.category = category;
        data.label = $(element).hasClass('cmp-step-by-step__prev') ? 'Pfeil zurueck' : 'Pfeil vor';
        data.value = '-';
        const step = $(element).closest('.cmp-step-by-step').find('.cmp-step-by-step__step');
        if (step.length > 0) {
            const text = step.text().replace(/\svon\s\d$/, '');
            data.action = text;
        }
        this.trackEvent(JSON.stringify(data));
    }

    /**
     * Tracks (given) accordion click.
     *
     * @param element {Element} - accordion to track
     */
    static trackAccordion(element) {
        let action = 'close';
        if (element.hasAttribute('class') && element.getAttribute('class').includes('open')) {
            action = 'open';
        }
        const data = {};
        data.category = CATEGORIES.accordion;
        data.action = action;
        data.label = this.jsonConform(element.querySelector('.cmp-accordionItem-title').textContent.trim());
        data.event = 'accordionClick';
        this.trackEvent(JSON.stringify(data));
    }

    /**
     * Tracks (given) button click in order to switch customer table (tariff table).
     *
     * @param element {Element} - customer table switch button
     * @param active {Boolean} - indicates whether the custom table is active (ie. visible)
     */
    static trackCustomerTableSwitch(element, active) {
        let label = 'aktiviert';
        if (!active) {
            label = 'deaktiviert';
        }
        const data = {};
        data.category = CATEGORIES.customerTableSwitch;
        data.event = 'generic';
        data.action = element.closest('.cmp-tariff-customer-switch').querySelector('span').textContent.trim();
        data.label = label;
        this.trackEvent(JSON.stringify(data));
    }

    /**
     * Tracks (given) collapsible item click.
     *
     * @param element {Element} - collapsible item to track
     * @param open {boolean} - if true the current action is expansion (aka open)
     */
    static trackCollapsibleItem(element, open) {
        let label = element.innerText.trim();
        const container = element.closest('.cmp-container--content');
        if (container !== null) {
            const headline = container.querySelector('h1, h2, h3, h4, h5, h6');
            if (headline !== null) {
                label = headline.textContent.trim();
            }
        }
        const data = {};
        data.category = CATEGORIES.collapsible;
        data.action = open ? 'open' : 'close';
        data.label = this.jsonConform(label);
        data.event = 'togglecontentClick';
        this.trackEvent(JSON.stringify(data));
    }

    /**
     * Tracks play event for the given video id.
     *
     * @param id {String} - video id to track
     */
    static trackVideo(id) {
        const data = {};
        data.category = CATEGORIES.video;
        data.action = 'play';
        data.label = id;
        data.event = 'videoClick';
        this.trackEvent(JSON.stringify(data));
    }

    /**
     * Tracks search icon (header) click.
     */
    static trackSearchIconClick() {
        const data = {};
        data.category = CATEGORIES.internalSearch;
        data.action = 'Globale Suche - Klick zur Suche';
        data.label = '-';
        data.event = 'generic';
        this.trackEvent(JSON.stringify(data));
    }

    /**
     * Tracks user search.
     *
     * @param searchQuery {String} - user input
     * @param totalHits {String} - number of search result entries
     */
    static trackSearch(searchQuery, totalHits) {
        const data = {};
        data.category = CATEGORIES.internalSearch;
        data.action = 'Globale Suche';
        data.label = this.jsonConform(searchQuery);
        data.searchTerm = this.jsonConform(searchQuery);
        data.event = 'generic';
        data.value = totalHits;
        this.trackEvent(JSON.stringify(data));
    }

    /**
     * Tracks facet change.
     *
     * @param searchQuery {String} - user input
     * @param facet {String} - selected facet
     */
    static trackSearchFacetChange(searchQuery, facet) {
        const data = {};
        data.category = CATEGORIES.internalSearch;
        data.action = 'Globale Suche - Klick auf Filter';
        data.label = facet;
        data.searchTerm = this.jsonConform(searchQuery);
        data.event = 'generic';
        this.trackEvent(JSON.stringify(data));
    }

    /**
     * Tracks facet change as virtual page view.
     *
     * @param facetString {String} - facets built as an URL parameter
     */
    static trackVirtualPageViewAfterFacetChange(facetString) {
        const data = {};
        data.event = 'virtualPageview';
        data.virtualPath = document.location.pathname + document.location.search;
        data.virtualPath += `&f=${facetString}&p=1`;
        this.trackEvent(JSON.stringify(data));
    }

    static trackVirtualPageViewFaq(vanity = '') {
        const data = {};
        data.event = 'virtualPageview';
        data.virtualPath = document.location.pathname.split(vanity)[1] || '/';
        this.trackEvent(JSON.stringify(data));
    }

    /**
     * Tracks search result click.
     *
     * @param searchQuery {String} - user input
     * @param resultItem {String} - search result item
     * @param index {String} - search result item index (zero based)
     * @param facets {String} - search facet(s)
     */
    static trackSearchResultClick(searchQuery, resultItem, index, facets) {
        const data = {};
        data.category = CATEGORIES.internalSearch;
        data.action = 'Globale Suche - Klick auf Suchergebnis';
        data.label = resultItem;
        data.searchTerm = this.jsonConform(searchQuery);
        data.event = 'generic';
        data.value = index + 1;
        data.searchCategory = facets;
        this.trackEvent(JSON.stringify(data));
    }

    /**
     * Tracks faq event.
     *
     * @param action {String} - Event action to set
     * @param label {String} - Event label to set
     */
    static trackFaqEvent(action, label) {
        const data = {};
        data.category = CATEGORIES.faq;
        data.action = action;
        data.event = 'generic';
        data.value = '-';
        data.label = this.jsonConform(label);
        this.trackEvent(JSON.stringify(data));
    }

    /**
     * Tracks faq feedback.
     *
     * @param selection {String} - either 'Ja' or 'Nein'
     */
    static trackFaqFeedback(selection) {
        this.trackFaqEvent('FAQ - Feedback Klick: Konnten wir Ihnen helfen?', selection);
    }

    /**
     * Tracks faq step-back-button.
     *
     * @param label {String} - current faq step
     */
    static trackFaqStepBack(label) {
        this.trackFaqEvent('FAQ - Klick auf Zurück Button', label);
    }

    /**
     * Tracks selected faq category only on start page.
     *
     * @param category {String} - selected category
     */
    static trackFaqCategoryStartPage(category) {
        this.trackFaqEvent('FAQ - Klick in Navigationsleiste', category);
    }

    /**
     * Tracks selected faq category other than on start page.
     *
     * @param category {String} - selected category
     */
    static trackFaqCategory(category) {
        this.trackFaqEvent('FAQ - Klick auf Kategorie Kachel', category);
    }

    /**
     * Tracks selected faq entry only on start page.
     *
     * @param title {String} - entry title (aka question)
     */
    static trackFaqEntryStartPage(title) {
        this.trackFaqEvent('FAQ - Klick in Übersichtsseite', title);
    }

    /**
     * Tracks selected step from step-by-step component.
     *
     * @param step {String} - step title
     */
    static trackFaqStep(step) {
        this.trackFaqEvent('FAQ - Steps', step);
    }

    /**
     * Tracks selected faq entry other than on start page.
     *
     * @param title {String} - entry title (aka question)
     */
    static trackFaqEntry(title) {
        this.trackFaqEvent('FAQ - Klick auf Detailseite', title);
    }

    /**
     * Track form submit or cancellation
     *
     * @param data {String} - data to track
     * @param submitted {Boolean} - if true user submitted the form otherwise left the (form) page
     * @param lastVisitedField {String} - last visited field in case the user left the (form) page
     * @param leadCategory {String} - leadCategory information to track if available
     */
    static trackForm(data, submitted, lastVisitedField, leadCategory) {
        if (data === undefined || data === '') {
            return;
        }
        let jsonData = data;
        if (typeof data === 'string' && data.indexOf("'") >= 0) {
            jsonData = data.replace(/'/gi, '"');
        }
        const dataAsJson = JSON.parse(jsonData);
        if (leadCategory !== undefined) {
            dataAsJson.leadCategory = leadCategory;
        }
        delete dataAsJson.eventReset; // only sent after reset button was clicked
        delete dataAsJson.actionReset; // see above
        if (submitted) {
            delete dataAsJson.actionCancel;
            delete dataAsJson.eventCancel;
            dataAsJson.action = dataAsJson.actionSubmit;
            dataAsJson.event = dataAsJson.eventSubmit;
            delete dataAsJson.actionSubmit;
            delete dataAsJson.eventSubmit;
            /* eslint-disable no-undef */
            if (dataAsJson.label === '' && Login !== undefined) {
                dataAsJson.label = Tracking.getFormLabel(Login);
            }
            /* eslint-disable no-undef */
        } else {
            delete dataAsJson.actionSubmit;
            delete dataAsJson.eventSubmit;
            dataAsJson.action = dataAsJson.actionCancel;
            dataAsJson.event = dataAsJson.eventCancel;
            delete dataAsJson.actionCancel;
            delete dataAsJson.eventCancel;
            dataAsJson.label = lastVisitedField;
        }
        Tracking.trackEvent(dataAsJson);
    }

    /**
     * Track form reset
     *
     * @param data {String} - data to track
     * @param leadCategory {String} - leadCategory information to track if available
     */
    static trackFormReset(data, leadCategory) {
        if (data === undefined || data === '') {
            return;
        }
        let jsonData = data;
        if (typeof data === 'string' && data.indexOf("'") >= 0) {
            jsonData = data.replace(/'/gi, '"');
        }
        const dataAsJson = JSON.parse(jsonData);
        if (leadCategory !== undefined) {
            dataAsJson.leadCategory = leadCategory;
        }
        delete dataAsJson.actionCancel;
        delete dataAsJson.actionSubmit;
        delete dataAsJson.eventCancel;
        delete dataAsJson.eventSubmit;

        dataAsJson.action = dataAsJson.actionReset;
        dataAsJson.event = dataAsJson.eventReset;
        delete dataAsJson.actionReset;
        delete dataAsJson.eventReset;

        /* eslint-disable no-undef */
        if (dataAsJson.label === '' && Login !== undefined) {
            dataAsJson.label = Tracking.getFormLabel(Login);
        }
        /* eslint-disable no-undef */
        Tracking.trackEvent(dataAsJson);
    }

    /**
     * Returns label either by checking whether the user is logged in as cable or mobile user or by analyzing the
     * current hash value.
     *
     * @param login {Object} - jQuery login plugin
     *
     * @returns {string} determined label
     */
    static getFormLabel(login) {
        let label = 'Neukunde';
        if (login.authenticated('mobile')) {
            label = 'Bestandskunde Mobil';
            // eslint-disable-next-line no-undef
        } else if (login.authenticated('cable')) {
            label = 'Bestandskunde Kabel';
        } else {
            // get mobile|cable information from URL
            // eslint-disable-next-line no-lonely-if
            if (window.location.hash !== '') {
                const type = window.location.hash.substr(window.location.hash.lastIndexOf('/') + 1);
                if (type === 'mobile') {
                    label = 'Bestandskunde Mobil';
                } else if (type === 'cable') {
                    label = 'Bestandskunde Kabel';
                }
            }
        }
        return label;
    }

    /**
     * Extracts text from given link element. Remove screenreader text before returning.
     *
     * @param a {Element} - link element to get text from
     *
     * @returns {string} link text
     */
    static getLinkTextWithoutScreenReaderText(a) {
        const {text} = a;
        const srTextElement = a.querySelector('span.sr-only');
        if (srTextElement !== null) {
            return text.replace(srTextElement.textContent.trim(), '').trim();
        }
        return text.trim();
    }

    /**
     * Tracks a click on a toggle button
     * @param active true if the toggle button is active
     * @param vfc true, if the toggle is within a VFC
     * @param tag the tag of the toggle button
     * @param singleSelect true, if only one toggle button can be selected
     */
    static trackToggleClick(active, vfc, tag, singleSelect) {
        const data = {};
        data.event = 'toggleClick';
        data.category = 'ToggleContent Click';
        data.action = active ? 'active' : 'inactive';
        data.buttonLocation = vfc ? 'VFC' : '';
        data.label = this.jsonConform(tag);
        data.singleSelect = singleSelect ? 'yes' : 'no';
        this.trackEvent(JSON.stringify(data));
    }

    /**
     * Tracks a click on a radio within the toggle button component
     * @param tag the tag of the relating toggle button
     * @param label the label of the radio
     */
    static trackToggleRadioClick(tag, label) {
        const data = {};
        data.event = 'radioButtonClick';
        data.category = 'RadioButton Click';
        data.action = 'active';
        data.buttonLocation = this.jsonConform(tag);
        data.label = this.jsonConform(label);
        this.trackEvent(JSON.stringify(data));
    }
}

export default Tracking;
