/**
 * Functionality for dropdown nav scroll navigation in case of anchor binding.
 */
class DropdownNavAnchor {
    // Constructor
    constructor(baseUseClass) {
        this.baseUseClass = baseUseClass;
        this.log = this.log.bind(this);
        this.init = this.init.bind(this);

        this.log('Constructor');
    }

    log(title, ...args) {
        if (this.baseUseClass.isDebugLoggingOn()) {
            console.log(`dropdownnavanchor.js | ${title}`, args);
        }
    }

    init() {
        this.log('init method started...');

        if (this.baseUseClass.isEditMode()) {
            this.log('Class is not going to perform any jumps in the EditMode.');
            return;
        }

        // in case of edit mode we have iframes
        let currentWindow = window;
        if (window.self !== window.top) {
            currentWindow = window.parent.document;
        }
        $(currentWindow).customOn('hashchange', (e) => {
            this.jumpToDropdownNav(e.currentTarget);
        });

        setTimeout(() => {
            this.jumpToDropdownNav(currentWindow);
        }, 10);

        this.log('init method completed');
    }

    jumpToDropdownNav(targetWindow) {
        if (!this.baseUseClass.isEditMode()) {
            this.log('Not in edit mode');
            const dataAnchorArrayElem = $('.cmp-dropdown-nav [data-anchor-array]');
            this.log('Anchor array element', dataAnchorArrayElem);
            const anchorArrayAttr = dataAnchorArrayElem.attr('data-anchor-array');

            if (!anchorArrayAttr) {
                this.log('Element with anchors array could not be found.');
                this.log('Class finished its work.');
                return;
            }

            if (anchorArrayAttr.length > 0) {
                const anchorArray = anchorArrayAttr.split(',');
                const hash = targetWindow.location.hash?.slice(1) ?? '';
                this.log('anchorArray', anchorArray);
                this.log('hash', hash);
                if (anchorArray.length > 0 && hash.length > 0 && anchorArray.includes(hash)) {
                    this.log('Includes hash!');
                    $('.cmp-dropdown-nav')[0]?.scrollIntoView();
                }
            }
        }
    }
}

export default DropdownNavAnchor;
