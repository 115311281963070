class StickyFooter {
    constructor(baseUseClass) {
        this.baseUseClass = baseUseClass;
        this.init = this.init.bind(this);
        this.core = this.core.bind(this);
        this.removeEventListeners = this.removeEventListeners.bind(this);
        this.acquireValues = this.acquireValues.bind(this);
        this.applyStyles = this.applyStyles.bind(this);
        this.applyStylesMobile = this.applyStylesMobile.bind(this);
        this.checkScrollPos = this.checkScrollPos.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        $(window).customOn('resizeend', () => {
            this.init();
        });
        document.addEventListener('editable-updated wcmModeChanged', () => {
            this.init();
        });
    }

    init() {
        this.stickyFooterWrapper = window.document.getElementsByClassName('cmp-stickyfooter--wrapper')[0];
        this.stickyBackToTop = window.document.getElementsByClassName('btn-sticky').length > 0 ?
            window.document.getElementsByClassName('btn-sticky')[0] : null;
        this.closeBtn = window.document.getElementsByClassName('cmp-stickyfooter--btn-close')[0];
        this.removeEventListeners();
        this.core();
    }

    core() {
        if (typeof this.stickyFooterWrapper === 'undefined') return;

        this.acquireValues();

        if (this.showCloseButton !== 'true' || this.baseUseClass.getCookie(this.uuid) !== 'closed' || this.baseUseClass.isEditMode() || this.baseUseClass.isPreviewMode()) {
            this.stickyFooterWrapper.style.display = 'block';
            this.stickyFooterWrapper.style.top = null;
            this.stickyFooterWrapper.style.bottom = null;
            this.stickyFooterWrapper.style.height = null;
            this.stickyFooterWrapper.style.borderBottom = null;
            this.stickyFooterWrapper.style.borderBottom = null;
            if (!this.baseUseClass.isEditMode() && this.baseUseClass.isMobile()) {
                this.applyStylesMobile();
                this.stickyFooterWrapper.classList.remove('fixed');
                if (this.footerChildren.length === 0) {
                    this.stickyFooterWrapper.classList.remove('fixed-mobile');
                    return;
                }
            } else {
                this.applyStyles();
                this.stickyFooterWrapper.classList.remove('fixed-mobile');
                if (this.footerChildren.length === 0) {
                    this.stickyFooterWrapper.classList.remove('fixed');
                    return;
                }
                this.checkScrollPos();
                window.addEventListener('scroll', this.handleScroll);
            }
            if (!this.baseUseClass.isEditMode() && !this.baseUseClass.isPreviewMode()) $(this.closeBtn).onClickEnter(() => this.handleClick());
        }
    }

    handleClick() {
        if (this.cookieLifetime && this.uuid) {
            this.baseUseClass.setCookie(this.uuid, 'closed', parseInt(this.cookieLifetime, 10));
        }
        this.stickyFooterWrapper.classList.add('fadeOut');
        if (this.baseUseClass.isMobile()) {
            document.querySelector('.main-content-wrapper').style.paddingTop = null;
        }
    }

    handleScroll() {
        this.checkScrollPos();
    }

    acquireValues() {
        this.uuid = this.stickyFooterWrapper.children[0].getAttribute('data-uuid');
        this.showCloseButton = this.stickyFooterWrapper.children[0].getAttribute('data-show-closebutton');
        this.cookieLifetime = this.stickyFooterWrapper.children[0].getAttribute('data-cookie-lifetime');
        this.backgroundColor = this.stickyFooterWrapper.children[0].getAttribute('data-background-color');
        this.borderColor = this.stickyFooterWrapper.children[0].getAttribute('data-border-color');
        this.stickyFooterWrapperHeight = this.stickyFooterWrapper.offsetHeight;
        this.stickyFooterGridHeight = this.stickyFooterWrapper.querySelector('.aem-Grid').offsetHeight;
        this.stickyFooterWrapperHeight = this.stickyFooterGridHeight > this.stickyFooterWrapperHeight ? this.stickyFooterGridHeight : this.stickyFooterWrapperHeight;
        this.viewportHeight = this.baseUseClass.isEditMode()
            ? window.parent.document.getElementById('ContentScrollView').offsetHeight
            : window.innerHeight;
        this.offsetFooter = window.document.getElementsByClassName('footer-navigation').length > 0
            ? window.document.getElementsByClassName('footer-navigation')[0].offsetTop : 0;
        this.footerChildren = window.document.getElementsByClassName('footer-navigation').length > 0
            ? window.document.getElementsByClassName('footer-navigation')[0].children : 0;
    }

    applyStyles() {
        if (this.borderColor) this.stickyFooterWrapper.style.borderTop = `1px solid ${this.borderColor}`;
        if (this.backgroundColor) this.stickyFooterWrapper.style.backgroundColor = this.backgroundColor;
    }

    applyStylesMobile() {
        if (this.borderColor) this.stickyFooterWrapper.style.borderBottom = `1px solid ${this.borderColor}`;
        if (this.backgroundColor) this.stickyFooterWrapper.style.backgroundColor = this.backgroundColor;
        this.stickyFooterWrapper.classList.add('fixed-mobile');
        this.stickyFooterWrapper.style.height = `${this.stickyFooterWrapperHeight}px`;
        const bannerHeight = this.baseUseClass.getBannerHeight();
        this.stickyFooterWrapper.style.top = `${bannerHeight}px`;
        let transformYValue = 50;
        if (window.document.getElementsByClassName('cmp-megamenu__subnav').length === 1) {
            transformYValue += window.document.getElementsByClassName('cmp-megamenu__subnav')[0].offsetHeight;
        }
        this.stickyFooterWrapper.style.transform = `translateY(${transformYValue}px)`;
        document.querySelector('.main-content-wrapper').style.paddingTop = `${this.stickyFooterWrapperHeight}px`;
    }

    removeEventListeners() {
        if (typeof this.closeBtn !== 'undefined') this.closeBtn.removeEventListener('click', this.handleClick);
        window.removeEventListener('scroll', this.handleScroll);
    }

    checkScrollPos() {
        this.scrollPosY = window.scrollY;
        if ((this.scrollPosY + this.viewportHeight) >= this.offsetFooter) {
            this.stickyFooterWrapper.classList.remove('fixed');
            this.stickyBackToTop?.classList.remove('fixed');
            this.offsetFooter = window.document.getElementsByClassName('footer-navigation').length > 0
                ? window.document.getElementsByClassName('footer-navigation')[0].offsetTop : 0;
        } else {
            this.stickyFooterWrapper.classList.add('fixed');
            this.stickyBackToTop?.classList.add('fixed');
        }
    }
}

export default StickyFooter;
