/*
* Icon Bar FE functionality
*/
class IconBar {
    // Constructor
    constructor(baseUseClass) {
        this.baseUseClass = baseUseClass;
        this.scrollElement = null;
    }

    // Main initialization
    init() {
        if (this.baseUseClass.isEditMode() === false) {
            this.initScrollFunctionality();
        }
        $(window).customOn('resizeend', () => {
            // reinitialize the scroll functionality
            this.initScrollFunctionality();
        });
    }

    /**
     * initializes the scroll functionality if the icon bar is wider than the view width
     */
    initScrollFunctionality() {
        // only of preview/publish mode
        const iconBars = $(document).find('.magat.cmp-iconbar');
        $(iconBars).each((index, iconBar) => {
            const iconBarHeader = $(iconBar).find('.cmp-iconbar--header');
            const windowWidth = $('.root').outerWidth();
            const iconBarUl = $(iconBar).find('nav ul');
            const iconBarWidth = iconBarUl.outerWidth() - 30;
            const scrollLeft = $(iconBar).find('.iconbar-scroll-left');
            const scrollRight = $(iconBar).find('.iconbar-scroll-right');
            // initialize scroll functionality only if the icon bar is wider than the window width
            if (scrollLeft.length > 0 && scrollRight.length > 0) {
                if (iconBarWidth > windowWidth && this.baseUseClass.isEditMode() === false) {
                    iconBarHeader.addClass('iconbar-scrollable');
                    // compute the height of the arrows because the height of the nav depends on the design and the content
                    const iconBarUlHeight = iconBarUl.outerHeight();
                    scrollLeft.css('height', iconBarUlHeight);
                    scrollRight.css('height', iconBarUlHeight);
                    // initially show only the right arrow
                    scrollLeft.hide();
                    scrollRight.css('display', 'flex');
                    // unbind for reinitialization
                    scrollLeft.unbind('click');
                    scrollLeft.click((e) => {
                        this.scrollIconBarNav(e.currentTarget, false);
                    });
                    scrollRight.unbind('click');
                    scrollRight.click((e) => {
                        this.scrollIconBarNav(e.currentTarget, true);
                    });
                    iconBarUl.unbind('touchstart');
                    iconBarUl.customOn('touchstart', (e) => {
                        this.scrollElement = e.currentTarget;
                    });
                    iconBarUl.unbind('touchmove');
                    iconBarUl.customOn('touchmove', (e) => {
                        if (this.scrollElement === e.currentTarget) {
                            this.hideScrollArrows(e.currentTarget);
                        }
                    });
                    iconBarUl.unbind('touchend');
                    iconBarUl.customOn('touchend', () => {
                        this.showScrollArrows(this.scrollElement);
                        this.scrollElement = null;
                    });
                } else {
                    // no scrolling -> hide arrows and remove class
                    iconBarHeader.removeClass('iconbar-scrollable');
                    scrollRight.hide();
                    scrollLeft.hide();
                }
            }
        });
    }

    /**
     * Show the scroll arrows dependent on the scroll state of the icon bar
     * @param iconBarUl the icon bar ul
     */
    showScrollArrows(iconBarUl) {
        const scrollLeft = $(iconBarUl).closest('.iconbar').find('.iconbar-scroll-left');
        const scrollRight = $(iconBarUl).closest('.iconbar').find('.iconbar-scroll-right');
        const scrollElem = $(iconBarUl).closest('.iconbar-scrollable');
        const currentScrollPos = scrollElem.scrollLeft();
        const navWidth = scrollElem.find('nav').outerWidth();
        const windowWidth = scrollElem.outerWidth();
        if (currentScrollPos > 0) {
            scrollLeft.css('display', 'flex');
        }
        if (currentScrollPos + windowWidth < navWidth) {
            scrollRight.css('display', 'flex');
        }
    }

    /**
     * Hide the scroll arrows
     * @param iconBarUl the icon bar ul
     */
    hideScrollArrows(iconBarUl) {
        const scrollLeft = $(iconBarUl).closest('.iconbar').find('.iconbar-scroll-left');
        const scrollRight = $(iconBarUl).closest('.iconbar').find('.iconbar-scroll-right');
        scrollLeft.hide();
        scrollRight.hide();
    }

    /**
     * scrolls the icon bar
     * @param elementClicked the right or left scroll arrow element
     * @param rightClicked true if the right scroll arrow was clicked
     */
    scrollIconBarNav(elementClicked, rightClicked) {
        const scrollElem = $(elementClicked).parent().find('.iconbar-scrollable');
        // consider the marin of the ul for calculation
        const currentScrollPos = scrollElem.scrollLeft();
        const windowWidth = scrollElem.outerWidth();
        if (rightClicked) {
            // consider the padding of the icon bar for calculation
            const newScrollPos = currentScrollPos + windowWidth;
            scrollElem.scrollLeft(newScrollPos);
            const maxScrollWidth = scrollElem.find('nav')[0].clientWidth - windowWidth;
            if (newScrollPos >= maxScrollWidth) {
                $(elementClicked).hide();
            }
            const scrollLeft = $(elementClicked).closest('.iconbar').find('.iconbar-scroll-left');
            scrollLeft.css('display', 'flex');
        } else {
            const newScrollPos = currentScrollPos - windowWidth;
            scrollElem.scrollLeft(newScrollPos);
            // positioning is at the beginning of the icon bar
            if (newScrollPos <= 0) {
                // hide the left arrow
                $(elementClicked).hide();
            }
            const scrollRight = $(elementClicked).closest('.iconbar').find('.iconbar-scroll-right');
            scrollRight.css('display', 'flex');
        }
    }
}

export default IconBar;
