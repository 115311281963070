import Tracking from '../../../clientlibs/clientlib-publish/src/js/tracking';

/*
* Header - .cmp-pageheader
*/
class PageHeader {
    constructor(baseUseClass) {
        this.baseUseClass = baseUseClass;
        this.init = this.init.bind(this);
        this.setCartCount = this.setCartCount.bind(this);
        this.process = this.process.bind(this);
        this.get = this.get.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.setMetaNavigation = this.setMetaNavigation.bind(this);
        this.initMainNavigationDesktop = this.initMainNavigationDesktop.bind(this);
        this.initMainNavigationMobile = this.initMainNavigationMobile.bind(this);
        this.setCondensedNavigation = this.setCondensedNavigation.bind(this);
        this.setNavigation = this.setNavigation.bind(this);
        this.toggleMainNavigation = this.toggleMainNavigation.bind(this);
        this.closeMainNavigation = this.closeMainNavigation.bind(this);
        this.setMainPadding = this.setMainPadding.bind(this);
        this.isMobileNavigation = this.isMobileNavigation.bind(this);
    }

    init() {
        this.settings = {
            selector: {
                header: '.cmp-pageheader',
                curtain: '#curtain',
                subBrandLogo: ' .main-navigation > .content-width > .text-logo',
                mainNavigationWidth: ' .main-navigation > .content-width',
                mainNavigation: ' .main-navigation',
                mainNavigationList: ' .main-navigation.sub-brand > div > ul',
                mainNavigationItem: ' .main-navigation > div > ul > li',
                mainNavigationItemLink: ' .main-navigation > div > ul > li:not(.more) > a',
                moreLink: ' .main-navigation .has-submenu.more',
                moreLinkList: ' .main-navigation .has-submenu.more > ul > .submenu',
                mainNavigationEntry: ' .main-navigation ul li.has-submenu',
                mainNavigationEntrySubmenu: ' .main-navigation ul li.has-submenu ul',
                mainNavigationItemCommunity: ' .main-navigation ul li.has-submenu.community',
                activeMainNavigationItems: ' .main-navigation ul#breadcrumblist li.has-submenu.active:not(li.has-submenu li.has-submenu)',
                burgerIcon: ' .animated-burger-icon',
                brandTypeSwitch: '.brand-bar .inline-meta-navigation',
                megamenu: '.cmp-megamenu',
                megamenuActiveItem: '.cmp-megamenu .active',
            },
            metaNavigationHeight: 30,
        };
        this.scrollPos = 0;
        this.offsetTop = 0;
        this.height = 0;
        this.condensedNavigation = 0;

        $('.magat.cmp-pageheader').each((index, element) => {
            this.element = $(element);
            this.process();
        });
    }

    process() {
        this.hasMegamenu = this.get('megamenu').length > 0;
        this.setCartCount();
        if (this.isMobileNavigation()) {
            this.initMainNavigationMobile(true);
        } else {
            this.initMainNavigationDesktop(true);
        }

        // iebug - cant use .onClickEnter
        document.querySelector('.brand-bar__mobile span.magenta-icon-close').addEventListener('click', this.closeMainNavigation);
        this.get('burgerIcon')[0].addEventListener('click', this.toggleMainNavigation);
        this.get('burgerIcon')[0].addEventListener('keydown', (e) => {
            if (e.keyCode && e.keyCode !== 13) {
                return;
            }
            this.toggleMainNavigation(e);
        });
        
        $(window).keydown((e) => {
            if (e.keyCode == 27) {
                this.closeMainNavigation(e);
            }
        });

        // iebug end
        $(window).customOn('scroll', this.onScroll);
        $(window).customOn('resizeend', this.onResize);

        $(window).trigger('resize');
    }

    setCartCount() {
        const count = this.baseUseClass.isBusinessPage() || document.location.host.indexOf('business') >= 0 ? this.baseUseClass.getCookie('businesscart_count') : this.baseUseClass.getCookie('cart_count');
        const $cartIcon = $('#magenta-page-header a.magenta-icon-cart, #magenta-page-header p.magenta-icon-cart');
        if (!count || !parseInt(count, 10)) {
            /** fake the number if on a non-production domain */
            if (window.location.origin.indexOf('magenta.at') === -1) {
                $cartIcon.addClass(`product-count-${3}`);
            }
            return;
        }
        $cartIcon.addClass(`product-count-${count}`);
    }

    /**
     * Get a DOM Element located beneath @element based on a given selector.
     * @param selector
     * @param param can be used to extend the selector. E.g.: "div" + ">p"
     */
    get(selector, param) {
        if (typeof param !== 'undefined') {
            return this.element.find(this.settings.selector[selector] + param);
        }
        return this.element.find(this.settings.selector[selector]);
    }

    onResize() {
        if (this.isMobileNavigation()) {
            this.initMainNavigationMobile();
        } else {
            this.initMainNavigationDesktop();
        }

        if (typeof document.fonts !== 'undefined') {
            document.fonts.ready.then(() => {
                this.setNavigation();
            });
        } else {
            setTimeout(this.setNavigation, 500);
        }
    }

    onScroll() {
        this.setMetaNavigation();
    }

    setMetaNavigation() {
        const scrollTop = $(document).scrollTop();
        if (scrollTop > this.scrollPos && scrollTop > this.settings.metaNavigationHeight) {
            this.element.addClass('scrolled');
            $('body').addClass('magenta-scrolled');
        } else if (scrollTop < this.scrollPos) {
            this.element.removeClass('scrolled');
            $('body').removeClass('magenta-scrolled');
        }
        this.scrollPos = scrollTop;
    }

    initMainNavigationDesktop(closeSubMenuItems) {
        this.get('mainNavigation').find('> .submenu').first().parent()
            .css('max-height', `calc(100vh - ${this.baseUseClass.getHeaderHeight()}px)`);

        this.get('mainNavigationEntry').customOff('click');

        // close initially opened sub-menu items
        if (closeSubMenuItems) {
            this.get('activeMainNavigationItems').each((index, item) => {
                $(item).toggleClass('active');
            });
        }

        this.get('mainNavigationEntrySubmenu').customOn('click', (e) => {
            if ($(e.target)[0].nodeName === 'A') {
                return;
            }
            e.preventDefault();
            e.stopPropagation();
        });

        this.get('mainNavigationEntry').first().onEnter((e) => {
            if (e.currentTarget.classList.contains('focus-restricted')) {
                e.currentTarget.classList.remove('focus-restricted');
                this.baseUseClass.killTabFocusRestrictor(e.currentTarget);
                return;
            }
            this.baseUseClass.applyTabFocusRestrictor(e.currentTarget);
            e.currentTarget.classList.add('focus-restricted');
        });

        this.get('mainNavigationEntry').customOn('click', (e) => {
            Tracking.trackNavigationEntry(e.currentTarget);
            const prevent = $(e.target).parent().hasClass('has-submenu');
            if (prevent) {
                e.preventDefault();
            }

            if ($(e.currentTarget)[0] === $(e.target).parent()[0] && $(e.target).parent().parents('.has-submenu.active').length > 0) {
                $(e.target).parent().siblings('li').removeClass('active');
                $(e.target).parent().toggleClass('active');
                e.stopPropagation();
                return;
            }

            if ($(e.currentTarget).hasClass('active')) {
                this.get('mainNavigationEntry').removeClass('active');
                this.get('mainNavigation').removeClass('submenu-active');
                $('#curtain').removeClass('show');
                return;
            }

            if ($(e.target).parent().hasClass('has-submenu.active')) {
                return;
            }
            this.get('mainNavigationEntry').removeClass('active');
            $(e.currentTarget).addClass('active');
            this.get('mainNavigation').addClass('submenu-active');
            $('#curtain').addClass('show');
        });

        $('#curtain').customOn('click', (e) => {
            $(e.currentTarget).removeClass('show');
            this.get('mainNavigation').removeClass('submenu-active');
            this.get('mainNavigationEntry').removeClass('active');

            $('html, body').css({overflow: '', position: '', height: ''});
        });

        !this.hasMegamenu && setTimeout(() => {
            this.get('mainNavigation').css('height', '');
            this.get('mainNavigationItem').filter(':visible').eq(0).css('margin-left', '0px');
        }, 0);

        this.setNecessaryMarginAndPadding();
    }

    initMainNavigationMobile(closeSubMenuItems) {
        this.get('mainNavigationEntry').customOff('click');

        // close initially opened sub-menu items
        if (closeSubMenuItems) {
            this.get('activeMainNavigationItems').each((index, item) => {
                $(item).toggleClass('active');
            });
        }

        this.get('mainNavigationEntry').customOn('click', (e) => {
            e.preventDefault();
            Tracking.trackNavigationEntry(e.currentTarget);

            if ($(e.currentTarget)[0] === $(e.target).parent()[0] && $(e.target).parent().parents('.has-submenu.active').length > 0) {
                $(e.target).parent().siblings('li').removeClass('active');
                $(e.target).parent().toggleClass('active');
                e.stopPropagation();
                return;
            }

            if ($(e.currentTarget).hasClass('active')) {
                this.get('mainNavigationEntry').removeClass('active');
                window.location.href = e.target.href;
                return;
            }

            if ($(e.target).parent().hasClass('has-submenu.active')) {
                return;
            }

            this.get('mainNavigationEntry').removeClass('active');
            $(e.currentTarget).addClass('active');

            this.get('mainNavigation').animate({
                scrollTop: $(e.currentTarget).offset().top - this.element.height(),
            }, 200);
        });

        $('#curtain').customOn('click', (e) => {
            $(e.currentTarget).removeClass('show');
            this.get('mainNavigationEntry').removeClass('active');
            this.get('mainNavigation').removeClass('submenu-active');
            this.get('burgerIcon').removeClass('active');
            $('body').removeClass('submenu-active');
            $('.cmp-pageheader').removeClass('submenu-active');
        });

        setTimeout(() => {
            this.get('mainNavigation').css('height', '');
            this.get('mainNavigationEntry').prop('style', false);
        }, 0);

        this.setNecessaryMarginAndPadding();
    }

    setCondensedNavigation() {
        this.get('moreLink').removeClass('show').removeClass('active');
        this.get('moreLinkList').empty();
        this.get('mainNavigationItemLink').parent(':not(.more)').show();
        this.get('mainNavigationList').prop('css', false);

        if (!(this.isMobileNavigation())) {
            let width = 0;
            const minMargin = 0;
            const widthLogo = this.get('subBrandLogo').length ? this.get('subBrandLogo').width() : 0;
            const widthContent = this.get('mainNavigationWidth').innerWidth();
            const availableWidth = widthContent - widthLogo;
            const moreLinkWidth = this.get('moreLink').width() + minMargin;

            let sortedItems = [];
            let arrId = 0;

            this.get('mainNavigationItemLink').each((index, el) => {
                if ($(el).parent('[data-condensed]').length) {
                    sortedItems[$(el).parent().data('condensed') + 100] = [
                        $(el).parent(),
                        $(el).width() + minMargin,
                    ];
                } else {
                    arrId += 1;
                    sortedItems[arrId] = [
                        $(el).parent(),
                        $(el).parent().width() + minMargin,
                    ];
                }
                width += $(el).parent().width() + minMargin;
            });

            if (width > availableWidth) {
                sortedItems[0] = [this.get('moreLink'), moreLinkWidth];
                width += moreLinkWidth;
            }

            sortedItems = sortedItems.reverse();

            sortedItems.map((value) => {
                if (width > availableWidth && typeof value !== 'undefined') {
                    value[0].clone().prependTo(this.get('moreLinkList'));
                    value[0].hide();
                    width -= value[1];
                    this.get('moreLink').addClass('show');
                    return true;
                }
                return false;
            });
            this.get('mainNavigationItemCommunity').appendTo(this.get('mainNavigationList'));
        } else {
            if (this.get('moreLink').hasClass('active')) {
                $('#curtain').removeClass('show');
            }

            this.get('mainNavigationItemCommunity').prependTo(this.get('mainNavigationList'));
        }
    }

    setNavigation() {
        this.setCondensedNavigation();

        if (this.isMobileNavigation()) {
            this.initMainNavigationMobile();
        } else {
            this.initMainNavigationDesktop();
        }
    }

    toggleMainNavigation(e) {
        e.preventDefault();
        if (!(this.isMobileNavigation())) {
            return;
        }
        const el = e.currentTarget.tagName === 'LI' ? e.currentTarget.children[0] : e.currentTarget;
        $(el).toggleClass('active').addClass('toggled');

        this.get('mainNavigation').toggleClass('submenu-active');
        $('body').toggleClass('submenu-active');
        $('.cmp-pageheader').toggleClass('submenu-active');

        if ($('.cmp-pageheader').hasClass('submenu-active')) {
            $('html, body').css({overflow: 'hidden', position: 'relative', height: '100%'});
            this.baseUseClass.applyTabFocusRestrictor($('.navigation-container'));
        } else {
            this.get('mainNavigation').find('.has-submenu.active').removeClass('active');
            $('html, body').css({overflow: '', position: '', height: ''});
            this.baseUseClass.killTabFocusRestrictor($('.navigation-container'));
        }

        !this.hasMegamenu && this.get('mainNavigation').css(
            'height',
            `${
                $(window).innerHeight() - this.element.innerHeight() - this.baseUseClass.getHeaderHeight()
            }px`,
        );

        if ($(el).hasClass('active')) {
            $('#curtain').addClass('show');
            $(el).attr("aria-expanded", true);
        } else {
            $('#curtain').removeClass('show');
            $(el).attr("aria-expanded", false);
        }
        e.stopPropagation();
    }

    closeMainNavigation(e) {
        e.preventDefault();
        if (!(this.isMobileNavigation())) {
            return;
        }
        this.get('burgerIcon').removeClass('active');
        this.get('burgerIcon').attr("aria-expanded", false);
        this.get('mainNavigation').removeClass('submenu-active');
        $('body').removeClass('submenu-active');
        $('.cmp-pageheader').removeClass('submenu-active');

        this.get('mainNavigation').find('.has-submenu.active').removeClass('active');
        $('html, body').css({overflow: '', position: '', height: ''});
        this.baseUseClass.killTabFocusRestrictor($('.navigation-container'));
        $('#curtain').removeClass('show');
    }

    setNecessaryMarginAndPadding() {
        const timeout = 351;
        this.setMainPadding(timeout);
        this.setScrollMargin(timeout + 5);
    }

    setMainPadding(timeout = 351) {
        setTimeout(() => {
            if (this.hasMegamenu && !this.baseUseClass.isEditMode() && this.get('mainNavigation').hasClass('submenu-active')) {
                return;
            }
            const padding = this.baseUseClass.getFixedContainerHeight();
            // Set padding-top to make place for header
            $('.magat.main:not(.app-view), .magat.xf-web-container > .overlay-page').css('padding-top', `${padding}px`);
            // Set maximum height of nav dropdown
            $('.magat.cmp-pageheader ul#breadcrumblist > li.has-submenu > ul > div').each((i, e) => {
                $(e).css('max-height', `calc(100vh - ${padding}px)`);
            });
        }, timeout);
    }

    setScrollMargin(timeout) {
        setTimeout(() => {
            if (this.hasMegamenu && !this.baseUseClass.isEditMode() && this.get('mainNavigation').hasClass('submenu-active')) {
                return;
            }
            const padding = this.baseUseClass.getFixedContainerHeight();

            $("a[href^='#']").each(function () {
                const hrefContent = $(this).attr('href').substring(1);
                $(`[id="${hrefContent}"]`).each(function () {
                    if (this) {
                        this.style.scrollMarginTop = `calc(1rem + ${padding}px)`;
                    }
                });
            });
        }, timeout);
    }

    isMobileNavigation() {
        return this.baseUseClass.isMobile() || (this.hasMegamenu && window.innerWidth < 1200);
    }
}

export default PageHeader;
