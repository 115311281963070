class Banner {
    constructor(baseUseClass) {
        this.baseUseClass = baseUseClass;
        this.init = this.init.bind(this);
    }

    init() {
        const initBannerInterval = window.setInterval(() => {
            if (typeof $ === 'function' && typeof $.fn.Login === 'function') {
                window.clearInterval(initBannerInterval);

                this.$banner = $('.magat .cmp-banner');
                this.$closeBtn = $('.magat .cmp-banner .close-banner');

                if (!this.$banner.length) {
                    return;
                }

                const {
                    uuid, onlineDate, offlineDate,
                } = this.$banner.data();

                fetch('/bin/public/servertime')
                    .then((resp) => {
                        if (resp.status !== 200) console.error('Banner: Failed to fetch servertime', resp.status);
                        return resp.text();
                    })
                    .then(
                        (time) => {
                            // Date.parse() returns the time between 1st January 1970 and the given Date in Milliseconds
                            const currentDateParsed = Date.parse(time);
                            const onlineDateParsed = Date.parse(onlineDate);
                            const offlineDateParsed = Date.parse(offlineDate);
                            const stored = this.baseUseClass.getCookie(uuid);
                            if (stored === 'closed') return; // User closed the banner
                            if (!Number.isNaN(onlineDateParsed) && (currentDateParsed <= onlineDateParsed)) return; // Before timeframe
                            if (!Number.isNaN(offlineDateParsed) && (currentDateParsed >= offlineDateParsed)) return; // After timeframe
                            this.$banner.show();
                            this.$closeBtn.customOff('click keydown');
                            this.$closeBtn.onClickEnter(() => {
                                this.$banner.slideUp(350, () => {
                                    $(window).trigger('resizeend');
                                });
                                this.baseUseClass.setCookie(uuid, 'closed');
                            });
                        },
                    );
            }
        }, 10);
    }
}

export default Banner;
