/*
* Variant 6 (Elements Product Offer Teaser) FE functionality
*/
class ProductOfferTeaser {
    // Constructor
    constructor(baseUseClass) {
        this.baseUseClass = baseUseClass;
        this.init = this.init.bind(this);
        this.toggleDetails = this.toggleDetails.bind(this);
    }

    // Main initialization
    init() {
        if (this.baseUseClass.isEditMode() === false) {
            $('.magat.cmp-productofferteaser .cmp-teaser__additional-details .cmp-collapsibleitem').customOn('click', (evt) => {
                this.toggleDetails(evt.currentTarget);
            });
        }
    }

    toggleDetails(targetElem) {
        // extend the additional details
        const detailsContent = $(targetElem).find('.cmp-collapsibleitem__content .cmp-collapsibleitem__content-toggle');
        $(detailsContent).slideToggle(200);
        const icons = $(targetElem).find('.cmp-teaser__text-icon');
        $(icons).toggle();

        // rewrite "details einblenden" button and symbol
        const openButton = $(targetElem).find('.cmp-collapsibleitem__content .cmp-collapsibleitem--label-align-center .cmp-collapsibleitem__link-open');
        const closeButton = $(targetElem).find('.cmp-collapsibleitem__content .cmp-collapsibleitem--label-align-center .cmp-collapsibleitem__link-close');
        // extendButton.text('Details ausblenden');
        openButton.toggle();
        closeButton.toggle();
    }
}

export default ProductOfferTeaser;
