class MegaMenu {
    constructor(baseUseClass) {
        this.baseUseClass = baseUseClass;
        this.setActive = this.setActive.bind(this);
        this.initEditor = this.initEditor.bind(this);
        this.initMobile = this.initMobile.bind(this);
        this.killMobile = this.killMobile.bind(this);
        this.initDesktop = this.initDesktop.bind(this);
        this.killDesktop = this.killDesktop.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleMouseenter = this.handleMouseenter.bind(this);
        this.handleFocusOut = this.handleFocusOut.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleCurtainMouseenter = this.handleCurtainMouseenter.bind(this);
        this.hideMegaMenu = this.hideMegaMenu.bind(this);
        this.setMainPadding = this.setMainPadding.bind(this);
        this.initPageLinkClick = this.initPageLinkClick.bind(this);
        this.handlePageLinkClick = this.handlePageLinkClick.bind(this);
        this.highlightMainNavigationItem = this.highlightMainNavigationItem.bind(this);
        this.selectors = {
            pageHeader: '.cmp-pageheader',
            megamenu: '.cmp-megamenu',
            megamenucontent: '.cmp-megamenu__content',
            megamenuitem: '.cmp-megamenu__item',
            slidecontainer: '.slide-container',
            itemActive: '.cmp-megamenu__item.active, .cmp-megamenu__linkgroup.active',
            itemLogin: '.cmp-megamenu__item--login',
            itemsWithSubmenuDesktop: '.cmp-megamenu__item--hasSubmenu',
            itemsWithNoSubmenuDesktop: '.cmp-megamenu__item--hasNoSubmenu',
            itemsWithSubmenuMobile: '.cmp-megamenu__item--hasSubmenu, .cmp-megamenu__linkgroup--hasSubmenu',
            megamenubackground: '.cmp-megamenu__background',
            utilityNavigation: '.utility-navigation',
            brandbarContainer: '.brand-bar > .content-width.container',
            utilityNavigationItems: '.brand-bar > .content-width.container > .utility-navigation > ul > li.tooltip.button',
            loomShuttle: '.loom-shuttle',
            mainContent: '.magat.main',
        };
        this.constants = {
            ITEM_CLASS: 'cmp-megamenu__item',
            ITEM_ACTIVE: 'active',
            SUBMENU_ACTIVE: 'submenu-active',
        };
        this.megamenu = document.querySelector(this.selectors.megamenu);
        this.isMobile = window.innerWidth < 1200;
        this.slidecontainer = document.querySelector(this.selectors.slidecontainer);
        this.slidecontainerPrevHeight = {
            0: this.slidecontainer?.offsetHeight + 24,
        };
        this.mobile = {};
        this.desktop = {};
    }

    init() {
        // abort if no megamenu present
        if (this.megamenu === null) {
            console.info('No Mega Menu found, skip processing.');
            return;
        }
        // START acquire items
        this.items = this.megamenu.querySelectorAll(this.selectors.megamenuitem);
        this.mobile.itemsWithSubmenu = this.megamenu.querySelectorAll(this.selectors.itemsWithSubmenuMobile);
        this.mobile.backButtons = this.megamenu.querySelectorAll('.cmp-megamenu__column__title');
        this.desktop.itemsWithSubmenu = this.megamenu.querySelectorAll(this.selectors.itemsWithSubmenuDesktop);
        this.desktop.itemsWithNoSubmenu = this.megamenu.querySelectorAll(this.selectors.itemsWithNoSubmenuDesktop);
        // END acquire items
        // START subscribe to PanelContainer
        if (this.baseUseClass.isEditMode() || this.baseUseClass.isTablet()) {
            if (window && window.Granite && window.Granite.author) {
                new window.Granite.author.MessageChannel('cqauthor', window).subscribeRequestMessage('cmp.panelcontainer', (msg) => {
                    msg?.data?.type === 'cmp-megamenu' && this.setActive(msg.data.index);
                });
                new window.Granite.author.MessageChannel('cqauthor', window).subscribeRequestMessage('activateMegaMenuItem', () => {
                    this.setActive(0);
                });
                new window.Granite.author.MessageChannel('cqauthor', window).subscribeRequestMessage('hideMegaMenu', () => {
                    const e = {
                        target: document.getElementById('curtain'),
                    };
                    this.handleCurtainMouseenter(e);
                });
            }
        }
        // END subscribe to PanelContainer
        // START init stuff
        if (this.baseUseClass.isEditMode()) {
            this.initEditor();
            window.addEventListener('resizeend', () => {
                if (this.isMobile) {
                    this.megamenu.children[0].classList.remove(this.constants.SUBMENU_ACTIVE);
                }
            });
        } else {
            window.addEventListener('resizeend', () => {
                this.isMobile ? this.initMobile() : this.killMobile();
                !(this.isMobile) ? this.initDesktop() : this.killDesktop();
            });
        }
        this.initPageLinkClick();
        this.highlightMainNavigationItem();
        // END init
    }

    /**
     * Highlights the active main navigation item and sub navigation item (if can be found)
     */
    highlightMainNavigationItem() {
        // get current page path
        const currentPath = $('.navigation-container.has-megamenu.published').length ? document.location.href : document.location.pathname;
        // get the last link clicked
        const lastLinkClickedStr = this.baseUseClass.readFromSessionStorage('magenta.data.lastLinkClicked');
        const menuLink = document.querySelectorAll(`.menu-item[href="${currentPath}"]`)[0];
        let mainMenuHref = '';
        // if the last link clicked is the same like the current page, use the date from session storage
        if (lastLinkClickedStr) {
            const lastLinkClicked = JSON.parse(lastLinkClickedStr);
            if (lastLinkClicked) {
                const lastLinkHref = lastLinkClicked.href;
                if (lastLinkHref && lastLinkHref === currentPath) {
                    mainMenuHref = lastLinkClicked.mainMenuHref;
                }
            }
        }
        // if main navigation item couldn't be determined, try to find is as a parent of the current active menu link
        if (!mainMenuHref || mainMenuHref.length === 0) {
            if (menuLink) {
                mainMenuHref = this.getMainMenuHrefForLink(menuLink, menuLink.getAttribute('href'));
            }
        }
        // if a main item could be determined, set the active class
        if (mainMenuHref && mainMenuHref.length > 0) {
            const mainMenuLink = document.querySelectorAll(`.mainmenu-item[href="${mainMenuHref}"]`)[0];
            mainMenuLink?.classList.add(this.constants.ITEM_ACTIVE);
            menuLink?.classList.add(this.constants.ITEM_ACTIVE);
            menuLink?.closest('.cmp-megamenu__linkgroup')?.getElementsByClassName('cmp-megamenu__linkgroup--title')[0]?.classList.add(this.constants.ITEM_ACTIVE);
        }
    }

    /**
     * Get all links of the page and add a click listener
     */
    initPageLinkClick() {
        const pageLinks = document.querySelectorAll('a');
        pageLinks.forEach((link) => {
            link.addEventListener('click', this.handlePageLinkClick);
        });
    }

    /**
     * Handle the click on any link of the page and write some properties for highlighting main navigation
     * @param e the click event
     */
    handlePageLinkClick(e) {
        const link = e.currentTarget;
        const href = link.getAttribute('href');
        const mainMenuHref = this.getMainMenuHrefForLink(link, href);
        const magentaLastLinkClicked = {};
        magentaLastLinkClicked.href = href;
        magentaLastLinkClicked.mainMenuHref = mainMenuHref;
        this.baseUseClass.writeToSessionStorage('magenta.data.lastLinkClicked', JSON.stringify(magentaLastLinkClicked));
    }

    /**
     * Delivers the href of the main menu item
     * @param link the current link element
     * @param href the href of the current link element
     * @returns the href of the main menu item or null
     */
    getMainMenuHrefForLink(link, href) {
        let mainMenuHref;
        // main menu item clicked
        if (link.parentNode.classList.contains('cmp-megamenu__item')) {
            mainMenuHref = href;
        } else {
            const mainMenuItem = link.closest('.cmp-megamenu__item');
            const mainMenuLink = mainMenuItem?.getElementsByTagName('a')[0];
            mainMenuHref = mainMenuLink?.getAttribute('href');
        }
        return mainMenuHref;
    }

    // click handler to expand content on mobile
    handleClick(e) {
        if (e.keyCode && e.keyCode !== 9) {
            return;
        }
        const element = (e.currentTarget || e.target).parentElement;
        e.preventDefault();
        const isActive = element.classList.contains(this.constants.ITEM_ACTIVE);
        (element.classList.contains('cmp-megamenu__linkgroup') ? element.parentElement : element)
            .parentElement.querySelectorAll(this.selectors.itemActive)
            .forEach((activeItem) => {
                activeItem.classList?.remove(this.constants.ITEM_ACTIVE);
            });
        !isActive && element.classList.add(this.constants.ITEM_ACTIVE);
        const level = e.target.getAttribute('data-level');
        if (level && this.slidecontainer) {
            this.slidecontainer.style.transform = `translate(-${level}00vw,0)`;
            this.slidecontainer.parentElement.style.height = `${e.target.nextElementSibling.offsetHeight + 24}px`;
            this.slidecontainerPrevHeight[level] = e.target.nextElementSibling.offsetHeight + 24;
            e.target.nextElementSibling.style.visibility = 'visible';
        }
    }

    handleBack(e) {
        if (e.keyCode && e.keyCode !== 9) {
            return;
        }
        const level = e.target.getAttribute('data-level');
        if (level && this.slidecontainer) {
            this.slidecontainer.style.transform = `translate(-${level}00vw, 0)`;
            this.slidecontainer.parentElement.style.height = `${this.slidecontainerPrevHeight[level]}px`; // 24 is containers margin bottom
            setTimeout(() => {
                e.target.parentElement.style.visibility = '';
            }, 350);
        }
    }

    // mouseenter handler to expand on desktop
    handleMouseenter(e) {
        if (this.isMobile) {
            return;
        }
        const mainNavigation = this.megamenu.children[0];
        const loomShuttle = this.megamenu.querySelector(this.selectors.loomShuttle);
        let element = e.target;
        if (element && element.tagName === 'A' && element.parentElement.classList.contains(this.constants.ITEM_CLASS)) {
            element = element.parentElement;
        } else {
            return;
        }

        // if device has no hover mechanism ( e.g. tablet ) preventDefault on link if element is not active already
        if (!window.matchMedia('(hover: hover)').matches && !window.matchMedia('(any-hover: hover)').matches) {
            if (!element.classList.contains(this.constants.ITEM_ACTIVE)) {
                e.preventDefault();
            }
        }
        const content = element.querySelector(this.selectors.megamenucontent);
        element.parentElement.querySelectorAll(this.selectors.itemActive)
            .forEach((activeItem) => {
                activeItem.classList?.remove(this.constants.ITEM_ACTIVE);
            });
        element.classList.add(this.constants.ITEM_ACTIVE);
        loomShuttle.style.left = `${element.offsetLeft}px`;
        loomShuttle.style.width = `${element.offsetWidth}px`;
        mainNavigation.classList.add(this.constants.SUBMENU_ACTIVE);
        if (content) {
            mainNavigation.style.height = `${content.offsetHeight + 82}px`; // 82 = header + separator
            this.setMainPadding(content.offsetHeight);
        }
        !this.baseUseClass.isEditMode() && document.getElementById('curtain')
            .classList
            .add('show');
    }

    handleFocusOut() {
        this.handleCurtainMouseenter({ target: document.getElementById('curtain') });
    }

    handleKeyDown(e){
        if (e.key === 'Escape') {
            this.hideMegaMenu();
            document.getElementById('curtain').classList.remove('show');
        }
    };

    hideMegaMenu() {
        const mainNavigation = this.megamenu.children[0];
        mainNavigation.style.height = '';
        mainNavigation.classList.remove(this.constants.SUBMENU_ACTIVE);
        this.megamenu.querySelectorAll(this.selectors.itemActive)
            .forEach((activeItem) => {
                activeItem?.classList.remove(this.constants.ITEM_ACTIVE);
            });
    }

    handleCurtainMouseenter(e) {
        this.hideMegaMenu();
        e.target.classList.remove('show');
    }

    handleBrandBarMouseenter() {
        this.hideMegaMenu();
        document.getElementById('curtain').classList.remove('show');
    }

    setActive(desiredIndex) {
        this.items.forEach((item, index) => {
            index === desiredIndex && this.handleMouseenter({ target: item.children[0] });
        });
    }

    initMobile() {
        // apply listener
        this.mobile.itemsWithSubmenu?.forEach((item) => {
            item.children[0].addEventListener('click', this.handleClick);
            item.children[0].addEventListener('keydown', this.handleClick);
        });
        this.mobile.backButtons?.forEach((button) => {
            button.addEventListener('click', this.handleBack);
            button.addEventListener('keydown', this.handleBack);
        });
    }

    killMobile() {
        this.mobile.itemsWithSubmenu?.forEach((item) => {
            item.children[0]?.removeEventListener('click', this.handleClick);
            item.children[0]?.removeEventListener('keydown', this.handleClick);
        });
        this.mobile.backButtons?.forEach((button) => {
            button.removeEventListener('click', this.handleBack);
            button.removeEventListener('keydown', this.handleBack);
        });
    }

    initDesktop() {
        const itemAlreadyActive = this.megamenu.querySelector(this.selectors.itemActive);
        itemAlreadyActive && this.handleMouseenter({ target: itemAlreadyActive });
        this.desktop.itemsWithSubmenu?.forEach((item, index) => {
            // if device has no hover mechanism ( e.g. tablet ) preventDefault on link if element is not active already
            if (!window.matchMedia('(hover: hover)').matches && !window.matchMedia('(any-hover: hover)').matches) {
                item.children[0].addEventListener('click', this.handleMouseenter);
                item.children[0].addEventListener('touch', this.handleMouseenter);
            } else {
                item.children[0].addEventListener('mouseenter', this.handleMouseenter);
                if (this.desktop.itemsWithSubmenu.length - 1 === index) {
                    const focusable = $(item)
                        .find(this.baseUseClass.focusableSelectorHidden);
                    const lastFocusable = focusable[focusable.length - 1];
                    lastFocusable.addEventListener('focusout', this.handleFocusOut);
                }
                item.children[0].addEventListener('focusin', this.handleMouseenter);
            }
        });
        this.desktop.itemsWithNoSubmenu?.forEach((item) => {
            item.addEventListener('mouseenter', this.handleFocusOut);
        });
        setTimeout(() => {
            document.addEventListener('keydown', this.handleKeyDown);
            document.getElementById('curtain')
                .addEventListener('mouseenter', this.handleCurtainMouseenter);
            if (document.getElementById('megamenuitem_login_desktop')) {
                document.getElementById('megamenuitem_login_desktop')
                    .addEventListener('mouseenter', () => {
                        this.handleCurtainMouseenter({
                            target: document.getElementById('curtain'),
                        });
                    });
            }
            $('.brand-bar').mouseenter(() => {
                this.handleBrandBarMouseenter();
            });
        }, 350);
    }

    killDesktop() {
        this.desktop.itemsWithSubmenu?.forEach((item) => {
            item.children[0].removeEventListener('click', this.handleMouseenter);
            item.children[0].removeEventListener('touch', this.handleMouseenter);
            item.children[0].removeEventListener('mouseenter', this.handleMouseenter);
            item.children[0].removeEventListener('focusout', this.handleFocusOut);
            item.children[0].removeEventListener('focusin', this.handleMouseenter);
        });
        document.getElementById('curtain')
            ?.removeEventListener('mouseenter', this.handleCurtainMouseenter);

        document.querySelector('.brand-bar')
            ?.removeEventListener('mouseenter', this.handleBrandBarMouseenter);

        document.removeEventListener('keydown', this.handleKeyDown)
    }

    setMainPadding(height) {
        if (!this.baseUseClass.isEditMode()) return;
        document.querySelector(this.selectors.mainContent).style.paddingTop = `${height + this.baseUseClass.getHeaderHeight()}px`;
    }

    initEditor() {
        this.handleMouseenter({ target: this.megamenu.querySelector(this.selectors.itemActive) });
    }
}

export default MegaMenu;
