/*
* PageFooter FE functionality
*/
class PageFooter {
    constructor(baseUseClass) {
        this.baseUseClass = baseUseClass;
        this.init = this.init.bind(this);
        this.process = this.process.bind(this);
        this.backToTop = this.backToTop.bind(this);
        this.onResize = this.onResize.bind(this);
        this.initFooterSlider = this.initFooterSlider.bind(this);
        this.toggleSlider = this.toggleSlider.bind(this);
        this.hideFooterNavigationSlider = this.hideFooterNavigationSlider.bind(this);
        this.showFooterNavigationSlider = this.showFooterNavigationSlider.bind(this);
        this.setNavigation = this.setNavigation.bind(this);
        this.get = this.get.bind(this);
    }

    init() {
        this.settings = {
            selector: {
                opener: '.footer-columns > li > p',
                sliderContent: '.footer-columns > li > ul',
            },
            accordion: false,
        };

        $('.magat.cmp-pagefooter').each((index, element) => {
            this.element = $(element);
            this.process();
        });
    }

    process() {
        this.initFooterSlider();
        // backToTop button is moved from footer to main content, but the functionality is still implemented here, because
        // main content has no own js ;-)
        $('body .btn-top-container .btn-top').on('click keyup', this.backToTop);
        this.get('opener').onClickEnter(this.toggleSlider);
        $(window).on('resize', this.onResize);
    }

    backToTop(e) {
        if (e.keyCode && e.keyCode !== 13) {
            return;
        }
        e.preventDefault();
        $('html, body').animate({ scrollTop: 0 });
    }

    onResize() {
        if (typeof document.fonts !== 'undefined') {
            document.fonts.ready.then(() => {
                this.setNavigation();
            });
        } else {
            setTimeout(this.setNavigation, 500);
        }
    }

    initFooterSlider() {
        if (this.baseUseClass.isMobile()) {
            this.hideFooterNavigationSlider();
        } else {
            this.showFooterNavigationSlider();
        }
    }

    toggleSlider(e) {
        if (!(this.baseUseClass.isMobile())) {
            return;
        }

        const opener = e.currentTarget;
        const content = $(opener).siblings('ul');

        this.get('sliderContent').each((index, el) => {
            if (el === content[0]) {
                $(opener).toggleClass('open');
                $(content).toggleClass('open').slideToggle(150);
            } else if (this.settings.accordion) {
                $(el).removeClass('open').slideUp(150);
                $(el).siblings('h4').removeClass('open');
            }
        });
    }

    hideFooterNavigationSlider() {
        this.get('sliderContent').hide();
    }

    showFooterNavigationSlider() {
        this.get('sliderContent').show();
    }

    setNavigation() {
        this.initFooterSlider();
    }

    /**
    * Get a DOM Element located beneath @element based on a given selector.
    * @param can be used to extend the selector. E.g.: "div" + ">p"
    */
    get(selector, param) {
        if (typeof param !== 'undefined') {
            return this.element.find(this.settings.selector[selector] + param);
        }
        return this.element.find(this.settings.selector[selector]);
    }
}
export default PageFooter;
