/**
 * Image FE functionality
 */
class Image {
    // Constructor
    constructor(baseUseClass) {
        this.baseUseClass = baseUseClass;
        this.pos = {
            top: 0, left: 0, x: 0, y: 0,
        };
        this.timestamp = null;
        // template data for the lightbox
        this.template = '<div class="magat cmp-image__lightbox">'
            + '<div class="image-container">'
            + '<img draggable="false" src=""/>'
            + '</div>'
            + '<div class="close-lightbox magenta-icon-close">'
            + '</div>'
            + '</div>';
    }

    // Main initialization
    init() {
        this.initLightbox($(document));
        $(window).customOn('hideLightbox', () => {
            if ($('.cmp-image__lightbox').length) {
                $('.cmp-image__lightbox').remove();
                $('.overlayCurtain').removeClass('show');
            }
        });
        $(document).customOn('refresh', (event, elem) => {
            if (!elem) {
                elem = document.body;
            }
            this.initLightbox(elem);
        });
    }

    /**
     * Initializes the lightbox functionality
     * @param elem the container element containing the image
     */
    initLightbox(elem) {
        const images = $(elem).find('.magat.cmp-image');
        $(images).each((index, image) => {
            this.initLightboxFunctionality($(image));
        });
    }

    /**
     * Initializes the lightbox functionality if it is configured
     * @param image the image
     */
    initLightboxFunctionality(image) {
        const imageLink = image.find('.cmp-image__link');
        // check if the lightbox is configured
        if (imageLink.length > 0 && imageLink.data('showLightbox')) {
            // on click show the lightbox
            $(imageLink).click((e) => {
                // reuse the overlay curtain
                $('.overlayCurtain').addClass('show');
                // set image url into the template and show the lightbox
                const imgSrc = $(e.currentTarget).data('lightboxImage');
                const myTempTemplate = $(this.template);
                myTempTemplate.find('img').attr('src', imgSrc);
                myTempTemplate.insertAfter('.overlayCurtain');
                myTempTemplate.find('.close-lightbox').click(() => {
                    $(window).trigger('hideLightbox');
                });
                // initialize zoom functionality if the image is bigger than the view
                const myImg = $(e.currentTarget).find('img');
                if (myImg.prop('naturalWidth') > $(window).width()
                    || myImg.prop('naturalHeight') > $(window).height()) {
                    myTempTemplate.find('.image-container').addClass('zoomable');
                    myTempTemplate.click((evt) => this.zoomEvent(evt));
                    this.pos = {
                        top: 0, left: 0, x: 0, y: 0,
                    };
                    myTempTemplate.find('.image-container').mousedown((evt) => this.startDragEvent(evt));
                }
            });
        }
    }

    /**
     * The drag event to enable moving the zoomed image within the screen
     * @param evt move event
     */
    dragEvent(evt) {
        const dx = evt.clientX - this.pos.x;
        const dy = evt.clientY - this.pos.y;

        // Scroll the element
        $(evt.currentTarget).scrollTop(this.pos.top - dy);
        $(evt.currentTarget).scrollLeft(this.pos.left - dx);
    }

    /**
     * Starts the drag event to enable moving the zoomed image within the screen
     * @param evt mouse down event
     */
    startDragEvent(evt) {
        if ($(evt.currentTarget).hasClass('zoomedIn')) {
            this.timestamp = new Date().getTime();
            $(evt.currentTarget).css('cursor', 'grabbing');
            $(evt.currentTarget).css('userSelect', 'none');

            this.pos = {
                left: $(evt.currentTarget).scrollLeft(),
                top: $(evt.currentTarget).scrollTop(),
                // Get the current mouse position
                x: evt.clientX,
                y: evt.clientY,
            };

            $(evt.currentTarget).mousemove((mvEvt) => this.dragEvent(mvEvt));
            $(evt.currentTarget).mouseup((upEvt) => this.stopDragEvent(upEvt));
        }
    }

    /**
     * Stops the drag event to enable moving the zoomed image within the screen
     * @param evt mouse up event
     */
    stopDragEvent(evt) {
        $(evt.currentTarget).css('cursor', '');
        $(evt.currentTarget).css('user-select', '');

        $(evt.currentTarget).unbind('mousemove');
        $(evt.currentTarget).unbind('mouseup');
    }

    /**
     * Zoom event to zoom images which are bigger than the current view
     * @param evt click event
     * @returns {boolean} true, if zoom event was executed
     */
    zoomEvent(evt) {
        const clickedImage = $(evt.currentTarget).find('img');
        if (clickedImage.hasClass('zoomedIn')) {
            // the time check we need that we don't mix up the drag and the zoom event
            const interval = 350;
            const timestamp2 = new Date().getTime();
            if ((timestamp2 - this.timestamp) > interval) {
                return false;
            }
            clickedImage.removeClass('zoomedIn');
            $(evt.currentTarget).find('.image-container').removeClass('zoomedIn');
        } else {
            clickedImage.addClass('zoomedIn');
            $(evt.currentTarget).find('.image-container').addClass('zoomedIn');
        }
        return true;
    }
}

export default Image;
