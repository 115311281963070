class Overlay {
    constructor(baseUseClass) {
        this.baseUseClass = baseUseClass;
        this.init = this.init.bind(this);
        this.showElement = this.showElement.bind(this);
        this.hideElement = this.hideElement.bind(this);
        this.readFromSessionStorage = this.readFromSessionStorage.bind(this);
        this.closeOverlay = this.closeOverlay.bind(this);
        this.showOverlay = this.showOverlay.bind(this);
        this.skipLoginOverlay = this.skipLoginOverlay.bind(this);
        this.prepareLoginOverlay = this.prepareLoginOverlay.bind(this);
        this.curtain = $('#overlayCurtain');
    }

    init() {
        this.curtain.customOn('click', () => {
            this.hideElement($(document).find('.cmp-overlay.show'));
        });
        // Get all overlay and check for default trigger
        $('body .magat.cmp-overlay').each((index, element) => {
            const data = $(element).data();
            const id = $(element).attr('id');
            const triggerEvent = data.overlayTrigger;
            const {cookieLifetime} = data;
            $(element).find('span.close-overlay').onClickEnter(() => {
                if (cookieLifetime && id) this.baseUseClass.setCookie(id, 'closed', parseInt(cookieLifetime, 10));
                this.closeOverlay($(element), triggerEvent);
            });
            const closeBtn = $(element).find('.cmp-overlay__close-btn');
            $(closeBtn).onClickEnter(() => {
                if (cookieLifetime && id) this.baseUseClass.setCookie(id, 'closed', parseInt(cookieLifetime, 10));
                this.closeOverlay($(element), triggerEvent);
            });
            $(window).customOn('keydown', (e) => {
                if (e.keyCode && e.keyCode === 27) {
                    if (cookieLifetime && id) this.baseUseClass.setCookie(id, 'closed', parseInt(cookieLifetime, 10));
                    this.closeOverlay($(element), triggerEvent);
                }
            });

            if (triggerEvent?.indexOf('page-load') === 0) {
                const autoDisplayOverlayOnTime = function (closedOverlays, showElementFkt) {
                    if (!closedOverlays.includes(element.id)) {
                        if (data.overlayDelay) {
                            try {
                                if (!window.timeouts) {
                                    window.timeouts = {};
                                }
                                window.timeouts[element.id] = window.setTimeout(() => {
                                    showElementFkt(element);
                                }, parseInt(data.overlayDelay, 10) * 1000);
                            } catch (e) {
                                console.error('Overlay trigger timeout is not of type Integer!', e);
                            }
                        } else {
                            showElementFkt(element);
                        }
                    }
                };

                const onLogin = triggerEvent === 'page-load-loggedin';
                // check if the overlay already has been closed once > don't open it again
                const closedOverlays = this.readFromSessionStorage();
                const showElemFkt = this.showElement;

                if (onLogin) {
                    $(window).customOn('loggedInEvent', () => {
                        autoDisplayOverlayOnTime(closedOverlays, showElemFkt);
                    });
                } else {
                    autoDisplayOverlayOnTime(closedOverlays, showElemFkt);
                }
            } else if (triggerEvent === 'page-scroll') {
                const anchor = data.overlayAnchor;
                if (anchor) {
                    let targetElement = $(`#${anchor}`)[0];
                    if (!targetElement) {
                        targetElement = $(`[href='#${anchor}']`)[0];
                    }
                    if (targetElement) {
                        $(window).scroll((e) => {
                            if (this.baseUseClass.elementIsVisible(targetElement)) {
                                $(e.currentTarget).customOff('scroll');
                                this.showElement(element);
                            }
                        });
                    }
                }
            }
        });

        const show = this.showOverlay;

        // Get all links on the page targeting an overlay
        // When clicked, check if the element is not visible
        // Clear a timeout that may be affecting the element
        $(document).customOn('click keydown', '.cmp-overlay__trigger', (e) => {
            (e.keyCode && e.keyCode !== 9) && e.preventDefault();
            if (e.keyCode && e.keyCode !== 13) {
                return;
            }
            const evtTarget = $(e.currentTarget);
            // check if initial overlay-trigger class still present
            if (!evtTarget.hasClass('cmp-overlay__trigger')) {
                return;
            }

            const target = $(`#${evtTarget.data().overlayTarget}`);
            if (target.length && !this.skipLoginOverlay(target, evtTarget.data().loginLink)) {
                show(e.currentTarget, target, evtTarget.data());
            }
        });

        // enable opening an overlay while selecting a form option of a dropdown
        $(document).customOn('change', '.cmp-overlay__selection-trigger', (e) => {
            const optionElem = $('option:selected', e.currentTarget);
            const data = $(optionElem).data();
            if (data && data.overlayTarget) {
                const target = $(`#${data.overlayTarget}`);
                // do not trigger Login Overlay if user is logged-in
                if (!this.skipLoginOverlay(target)) {
                    show(optionElem, target, data);
                }
            }
        });

        // enable opening an overlay while clicking on a form option (checkbox, radio, icon selection)
        $(document).customOn('click', '.cmp-overlay__option-trigger', (e) => {
            const elem = $(e.currentTarget).find('[data-overlay-target]');
            const data = $(elem).data();
            if (data && data.overlayTarget) {
                const target = $(`#${data.overlayTarget}`);
                // do not trigger Login Overlay if user is logged-in
                if (!this.skipLoginOverlay(target)) {
                    show(elem, target, data);
                }
            }
        });
    }

    showOverlay(element, target, data) {
        if (!target.hasClass('show')) {
            this.prepareLoginOverlay($(element));
            this.showElement(target);
            $('.overlay__triggered').removeClass('overlay__triggered');
            $(element).addClass('overlay__triggered');
            if (window.timeouts) {
                window.clearTimeout(window.timeouts[data.overlayTarget]);
            }
        }
    }

    skipLoginOverlay(target, link) {
        const loginOvAndloggedIn = !!(target.hasClass('magenta-login-overlay') && window.Login && window.Login.authenticated());

        // redirect directly if target is Login Overlay and user authenticated & login-link set
        if (loginOvAndloggedIn) {
            if (link) {
                if (window.Login.authenticatedWidthSession()) {
                    // Logged-in and has session, so directly call target
                    window.location.href = link;
                } else {
                    // Logged-in but no session, so redirect to profileswitch to create session with selected product and forward to target
                    window.location.assign(`${document.location.origin}/profileswitch?referrer=${encodeURIComponent(link)}`);
                }
            } else if (!window.Login.authenticatedWidthSession()) {
                // lgged-in but no link target and no session - redirect to profileswitch with current location as referrer
                window.location.assign(`${document.location.origin}/profileswitch?referrer=${encodeURIComponent(document.location.href)}`);
            }
        }

        return loginOvAndloggedIn;
    }

    prepareLoginOverlay(element) {
        if (element.data('login-link') && window.Login) {
            window.Login.prepare(element.data('login-link'), null);
        }
    }

    closeOverlay(overlay, triggerEvent) {
        this.hideElement(overlay);
        // prevent showing page load overlays on every page after closing once
        if (triggerEvent === 'page-load') {
            const closedOverlays = this.readFromSessionStorage();
            if (!closedOverlays.includes(overlay.id)) {
                closedOverlays.push(overlay.id);
            }
            this.baseUseClass.writeToSessionStorage('closedOverlays', closedOverlays);
        }
    }

    showElement(element) {
        if (!this.baseUseClass.isEditMode() && (this.baseUseClass.getCookie($(element).attr('id')) !== 'closed' || !this.baseUseClass.getCookie($(element).attr('id')))) {
            $(element).addClass('show');
            this.baseUseClass.applyTabFocusRestrictor(element);
            $(element).find(this.baseUseClass.focusableSelector).first().focus();
            this.curtain.addClass('show');
        }
    }

    hideElement(element) {
        $(element).removeClass('show');
        this.curtain.removeClass('show');
        $(window).trigger('hideLightbox');
        this.baseUseClass.killTabFocusRestrictor(element);
        setTimeout(() => {
            const lastActiveTrigger = $(`[data-overlay-target="${element.attr('id')}"].overlay__triggered`);
            lastActiveTrigger.length ? lastActiveTrigger.focus() : $('.overlay_triggered').focus();
        }, 20);
    }

    readFromSessionStorage() {
        let closedOverlays = [];
        const fromStorage = sessionStorage.getItem('closedOverlays');
        if (fromStorage) {
            closedOverlays = closedOverlays.concat(fromStorage);
        }
        return closedOverlays;
    }
}

export default Overlay;
