const Constants = {
    BFF_AUTH_TOKENS: 'bff_auth_tokens',
    BUTTON_QUERY: '.megamenuitem_login--link',
    LOGOUT_BUTTON_QUERY: '.logout-button',
};

class SelfcareImpl {
    constructor(baseUseClass, config, button) {
        this.baseUseClass = baseUseClass;
        this.config = config;
        this.retrieveToken = this.retrieveToken.bind(this);
        this.validateToken = this.validateToken.bind(this);
        this.init = this.init.bind(this);
        this.run = this.run.bind(this);
        this.handleLogoutButton = this.handleLogoutButton.bind(this);
        this.killLogoutButton = this.killLogoutButton.bind(this);
        this.logoutHandler = this.logoutHandler.bind(this);
        this.ctx = {
            button,
        };
        if (this.ctx.button) {
            this.token = null;
            this.loggedInTextBtn = null;
            this.loggedOutTextBtn = null;
            this.loginText = this.ctx.button.innerText;
            this.portalText = null;
        }
    }

    retrieveToken() {
        this.token = localStorage.getItem(Constants.BFF_AUTH_TOKENS)
            ? JSON.parse(localStorage.getItem(Constants.BFF_AUTH_TOKENS))
            : this.baseUseClass.getCookie(Constants.BFF_AUTH_TOKENS)
                ? JSON.parse(this.baseUseClass.getCookie(Constants.BFF_AUTH_TOKENS))
                : null;
    }

    validateToken() {
        this.retrieveToken();
        const checkExpiry = () => {
            const now = Math.round(Date.now() / 1000);
            return now < this.token['accessExpiresIn'];
        };
        if (this.token && checkExpiry()) {
            window.validSelfcareToken = true;
            return true;
        }
        window.validSelfcareToken = false;
        return false;
    }

    init() {
        if (this.ctx.button) {
            this.loggedInTextBtn = this.ctx.button.getAttribute('data-logged-in-text');
            this.loggedOutTextBtn = this.ctx.button.getAttribute('data-logged-out-text');
            this.loginUrl = this.ctx.button.getAttribute('data-login-page-path');
            this.logoutUrl = this.ctx.button.getAttribute('data-logout-url');

            this.run();
            window.addEventListener('selfcareLogin', () => {
                this.run();
            });
        }
    }

    run() {
        this.ctx.logoutButton = document.querySelector(Constants.LOGOUT_BUTTON_QUERY);
        const buttonContent = this.ctx.button.querySelector('span:nth-child(2), p:nth-child(2), p.small');
        if (this.validateToken()) {
            this.ctx.button.href = '/mein-magenta-kabel/dashboard';
            if (buttonContent) {
                buttonContent.innerText = this.loggedInTextBtn;
            }
            document.body.classList.add('logged-in');
            this.ctx.logoutButton && this.handleLogoutButton(this.ctx.logoutButton);
        } else {
            this.ctx.button.href = this.loginUrl;
            if (buttonContent) {
                buttonContent.innerText = this.loggedOutTextBtn;
            }
            document.body.classList.remove('logged-in');
            this.ctx.logoutButton && this.killLogoutButton(this.ctx.logoutButton);
        }
    }

    handleLogoutButton(btn) {
        btn.addEventListener('click', this.logoutHandler);
        btn.addEventListener('keydown', this.logoutHandler);
    }

    killLogoutButton(btn) {
        btn.removeEventListener('click', this.logoutHandler);
        btn.removeEventListener('keydown', this.logoutHandler);
    }

    logoutHandler(e) {
        if (e.key && e.key !== 'Enter') return;
        if (this.validateToken()) {
            e.preventDefault();
            const endpoint = `${this.config['bff_domain']}${this.config['endpoints']['logout']}?deviceId=${this.token.deviceId}`;
            fetch(endpoint, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${this.token['accessToken']}`,
                    'x-request-session-id': this.token['sessionUuid'],
                    'x-request-tracking-id': this.baseUseClass.generateUuidv4(),
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        localStorage.removeItem(Constants.BFF_AUTH_TOKENS);
                        this.baseUseClass.deleteCookie(Constants.BFF_AUTH_TOKENS)
                            .then(() => {
                                this.run();
                                // also logout mobile as requested by customer
                                window.history.pushState({...window.history.state}, '', this.logoutUrl);
                                window.location.reload();
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    } else {
                        console.error('Selfcare Logout failed!');
                    }
                });
        }
    }
}

export default class Selfcare {
    constructor(baseUseClass) {
        this.baseUseClass = baseUseClass;
        this.configUrl = window.angularConfigUrl;
        this.init = this.init.bind(this);
        this.getConfig = this.getConfig.bind(this);
    }

    async init() {
        const loginButtons = document.querySelectorAll(Constants.BUTTON_QUERY);
        if (loginButtons.length < 1) { return; }
        const config = await this.getConfig();
        loginButtons.forEach((button) => {
            // eslint-disable-next-line no-new
            new SelfcareImpl(this.baseUseClass, config, button).init();
        });
    }

    getConfig() {
        return fetch(`${this.configUrl}/angular/selfcare/global.config.json`)
            .then((res) => res.json())
            .then((json) => json);
    }
}
