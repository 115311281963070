class Text {
    constructor(baseUseClass) {
        this.baseUseClass = baseUseClass;
        this.init = this.init.bind(this);
        this.selector = '.magat.cmp-text';
    }

    init() {
        $(this.selector).each((i, text) => {
            if ($(text).find('table')) {
                $(text).find('table').each((j, table) => {
                    $(table).wrap('<div class="scroll"></div>');
                });
            }
        });
    }
}

export default Text;
