/**
 * Copyright 2019 Telekom Multimedia Solutions
 */
/** IMPORT */
import 'lazysizes';
import 'focus-visible';

import BaseUseClass from 'Commons/js/baseUseClass';
import Banner from 'Components/banner/banner';
import Global from 'Commons/js/global';
import Carousel from 'Components/carousel/carousel';
import Container from 'Components/container/container';
import Customhtml from 'Components/customhtml/customhtml';
import DropDown from 'Components/dropdown/dropdown';
import DropdownNavAnchor from 'Components/dropdownnav/dropdownnavanchor';
import Hardwaresupportiframe from 'Components/hardwaresupportiframe/hardwaresupportiframe';
import IconBar from 'Components/iconbar/iconbar';
import Image from 'Components/image/image';
import MegaMenu from 'Components/structure/megamenu/megamenu';
import Overlay from 'Components/overlay/overlay';
import PageHeader from 'Components/structure/pageheader/pageheader';
import PageFooter from 'Components/structure/pagefooter/pagefooter';
import ProductOfferTeaser from 'Components/teaser/variant_6/variant_6';
import TeaserContainer from 'Components/teasercontainer/teasercontainer';
import Text from 'Components/text/text';
import StickyFooter from 'Components/stickyfooter/stickyfooter';
import applyPolyfills from './polyfills';
import Selfcare from '../../../../components/selfcare/selfcare';

'use strict';

/**
 * STATE LOADING
 * Caution : HTMLElements may be missing at this points
 */

const baseUseClass = new BaseUseClass();

/** Latest jQuery */
if (window.$ === undefined || window.jQuery === undefined) {
    window.jQuery = require('jquery');
    window.$ = window.jQuery;
}

/**
 * STATE INTERACTIVE
 * Caution : DOM parsed, but Stylesheets or Imagery may be still loading at this point
 */
const stateInteractiveCheckInterval = setInterval(() => {
    if (document.readyState === 'interactive' || document.readyState === 'complete') {
        clearInterval(stateInteractiveCheckInterval);

        /** Polyfills */
        applyPolyfills();
    }
}, 10);

/**
 * STATE COMPLETE
 */
const stateCompleteCheckInterval = setInterval(() => {
    if (document.readyState === 'complete') {
        clearInterval(stateCompleteCheckInterval);

        /** Components */
        new Global(baseUseClass).init();
        new Selfcare(baseUseClass).init();
        new Text().init();
        new Customhtml().init();
        new ProductOfferTeaser(baseUseClass).init();
        new Carousel(baseUseClass).init();
        new Banner(baseUseClass).init();
        new TeaserContainer(baseUseClass).init();
        new PageHeader(baseUseClass).init();
        new PageFooter(baseUseClass).init();
        new MegaMenu(baseUseClass).init();
        new Container(baseUseClass).init();
        new Overlay(baseUseClass).init();
        new DropDown(baseUseClass).init();
        new IconBar(baseUseClass).init();
        new Image(baseUseClass).init();
        new StickyFooter(baseUseClass).init();
        new Hardwaresupportiframe().init();
        new DropdownNavAnchor(baseUseClass).init();

        /** jQuery Datepicker */
        jQuery(function ($) {
            $.datepicker.regional['de-AT'] = {
                closeText: 'Schließen',
                prevText: '&#x3C;Zurück',
                nextText: 'Vor&#x3E;',
                currentText: 'Heute',
                monthNames: ['Jänner', 'Februar', 'März', 'April', 'Mai', 'Juni',
                    'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                monthNamesShort: ['Jän', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun',
                    'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
                dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
                dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                weekHeader: 'KW',
                dateFormat: 'dd.mm.yy',
                firstDay: 1,
                isRTL: false,
                showMonthAfterYear: false,
                yearSuffix: '',
            };
            $.datepicker.setDefaults($.datepicker.regional['de-AT']);
        });
        const event = new Event('immediateJSLoadFinished');
        document.dispatchEvent(event);
    }
}, 10);
