// JS logic for container component
class Container {
    // constructor
    constructor(baseUseClass) {
        this.baseUseClass = baseUseClass;
        this.containerQuery = '.magat.cmp-container.lazyloaded, .magat.cmp-container.no-lazyload, .magat.main.editmode .magat.cmp-container';
        this.init = this.init.bind(this);
        this.setPaddings = this.setPaddings.bind(this);
        this.setBackgroundImage = this.setBackgroundImage.bind(this);
        this.fetchRendition = this.fetchRendition.bind(this);
        this.setAssetHeight = this.setAssetHeight.bind(this);
        this.process = this.process.bind(this);
    }

    // initialization
    async init() {
        /**
         * Refresh containers after changing events
         */
        ['editable-updated', 'refresh'].forEach((type) => {
            document.addEventListener(type, () => {
                this.queryContainers().forEach((container) => this.process(container));
            });
        });

        ['resizeend', 'orientationchange'].forEach((type) => {
            window.addEventListener(type, () => {
                this.queryContainers().forEach((container) => {
                    $(container).data('bg-initialized', false);
                    this.process(container);
                });
            });
        });

        const setBgFct = this.setBackgroundImage;
        $(document).customOn('lazybeforeunveil', function (evt) {
            setBgFct($(evt.target));
        });

        /**
         * Process all containers that have been lazyloaded
         */
        document.addEventListener('lazybeforeunveil', (event) => {
            this.process(event.target);
        });

        /**
         * Process all containers that are located inside a carousel, once initialized
         */
        document.querySelectorAll('.owl-carousel').forEach((carousel) => {
            $(carousel).customOn('initialized.owl.carousel', () => {
                this.queryContainers(carousel).forEach((container) => this.process(container));
            });
        });

        /**
         * Process all containers that are _not_ located inside a carousel
         */
        window.setTimeout(() => {
            this.queryContainers()
                .filter((container) => !container.closest('.cmp-carousel'))
                .forEach((container) => this.process(container));
        }, 10);
    }

    queryContainers(root = document) {
        return Array.from(root.querySelectorAll(this.containerQuery));
    }

    // process lazy container
    async process(element) {
        await this.setBackgroundImage($(element));
        if (!this.baseUseClass.isEditMode()) {
            this.setHeight($(element));
        }
        this.setPaddings($(element));
        this.makeClickable($(element));
    }

    makeClickable(container) {
        if ($(container).hasClass('cmp-container__clickable')) {
            $(container).on('mouseup', (e) => {
                if (e.button === 0) {
                    const button = $(e.currentTarget).find('.cmp-button .magat-btn');
                    if (button.length > 0) {
                        $(button)[0].click();
                    }
                    e.preventDefault();
                    e.stopPropagation();
                }
            });
        }
    }

    // Fetch a rendition of the authored background image according to the current screen width
    async setBackgroundImage(container) {
        const $container = $(container);
        if ($container.data('bg-initialized')) {
            return;
        }

        $container.data('bg-initialized', 'true');
        const viewportWidth = $(window).width();
        const {
            md, lg, xl, xxl,
        } = this.baseUseClass.breakpoints;
        // eslint-disable-next-line no-unused-vars
        const renditionWidth = viewportWidth <= md
            ? md
            : viewportWidth <= lg
                ? lg
                : viewportWidth <= xl
                    ? xl
                    : xxl;
        // if mobile, process second background container which is for mobile
        let $backgroundDiv;
        let imageReference;
        if (viewportWidth < md) {
            $backgroundDiv = $container
                .children('.cmp-container--background.cmp-container--onlymobile');
            imageReference = $backgroundDiv?.data()?.backgroundReference;
        } else if (viewportWidth < lg) {
            $backgroundDiv = $container
                .children('.cmp-container--background.cmp-container--onlytablet');
            imageReference = $backgroundDiv?.data()?.backgroundReference;
        } else if (viewportWidth < xl) {
            $backgroundDiv = $container
                .children('.cmp-container--background.cmp-container--smallDesktop');
            imageReference = $backgroundDiv?.data()?.backgroundReference;
        } else if (viewportWidth >= xl) {
            $backgroundDiv = $container
                .children('.cmp-container--background').first();
            imageReference = $backgroundDiv?.data()?.backgroundReference;
        }

        if (imageReference === undefined) {
            $backgroundDiv = $container
                .children('.cmp-container--background').first();
            imageReference = $backgroundDiv?.data()?.backgroundReference;
        }

        if (imageReference === undefined) {
            return;
        }
        const {useAssetHeight, useRealHeight} = $backgroundDiv.data();
        if (imageReference) {
            let originalPath = `${imageReference}/jcr:content/renditions/original`;
            if (this.baseUseClass.isWebPSupported() && !imageReference.endsWith('.svg')) {
                originalPath = `${imageReference}/jcr:content/renditions/original.webp`;
            }
            let objectUrl;
            let blob;
            if (renditionWidth && !imageReference.endsWith('.svg')) {
                let renditionPath;
                if (this.baseUseClass.isWebPSupported()) {
                    renditionPath = `${imageReference}/jcr:content/renditions/rendition.${renditionWidth}.${renditionWidth}.webp`;
                } else {
                    renditionPath = originalPath;
                }
                if (!objectUrl) {
                    blob = await this.fetchRendition(renditionPath);
                    if (blob) {
                        objectUrl = URL.createObjectURL(blob);
                    } else {
                        blob = await this.fetchRendition(originalPath);
                        if (blob) {
                            objectUrl = URL.createObjectURL(blob);
                        }
                    }
                }
            } else if (!objectUrl) {
                blob = await this.fetchRendition(originalPath);
                if (blob) {
                    objectUrl = URL.createObjectURL(blob);
                }
            }
            if (blob && objectUrl) {
                if (useAssetHeight) this.setAssetHeight(container, blob, useRealHeight);
                const hAlign = $backgroundDiv?.data()?.hAlign ? $backgroundDiv?.data()?.hAlign : 'center';
                const vAlign = $backgroundDiv?.data()?.vAlign ? $backgroundDiv?.data()?.vAlign : 'center';
                $backgroundDiv.css('backgroundImage', `url("${objectUrl}")`);
                $backgroundDiv.css('backgroundPosition', `${hAlign} ${vAlign}`);
                if (!useAssetHeight && imageReference.endsWith('.svg')) {
                    $backgroundDiv.css('backgroundSize', '100% 100%');
                }
            }
        }
    }

    /**
     *
     * @param path to the image
     */
    fetchRendition(path) {
        try {
            return fetch(path)
                .then((response) => {
                    if (response.ok) {
                        return response.blob();
                    }
                    return null;
                })
                .then((blob) => blob);
        } catch (e) {
            return null;
        }
    }

    /**
     * Set the container height according to the aspect ratio of the image
     * @param container the DOM Node that receives the height
     * @param imageBlob the binary image
     * @param useRealHeight use the real height of the image, not the aspect ratio related
     */
    setAssetHeight(container, imageBlob, useRealHeight) {
        const dummyImg = document.createElement('IMG');
        if (this.baseUseClass.isDesktop() && useRealHeight) {
            dummyImg.style.visibility = 'hidden';
            dummyImg.style.width = '100%';
            container.find('.cmp-container--background')[0].appendChild(dummyImg);
        }
        dummyImg.onload = () => {
            const aspectRatio = dummyImg.naturalHeight / dummyImg.naturalWidth;
            const proportionateHeight = this.baseUseClass.isDesktop()
                && useRealHeight ? dummyImg.height : $(container).width() * aspectRatio;
            $(container).height(proportionateHeight);
            dummyImg.remove();
        };
        dummyImg.src = URL.createObjectURL(imageBlob);
    }

    setHeight(container) {
        let heightConfigName;
        if (this.baseUseClass.isMobile()) {
            heightConfigName = 'height-mobile';
        } else if (this.baseUseClass.isTablet()) {
            heightConfigName = 'height-tablet';
        } else if (this.baseUseClass.isSmallDesktop()) {
            heightConfigName = 'height-small-desktop';
        } else if (this.baseUseClass.isDesktop()) {
            heightConfigName = 'height-desktop';
        }
        // get the config from data attribute
        const height = container.data(heightConfigName) ? parseInt(container.data(heightConfigName), 10) : 0;
        const contentElem = container.find('.cmp-container--content')
            .first();
        if (contentElem.length > 0 && height > 0) {
            const heightInPx = `${height}px`;
            contentElem.css('height', heightInPx);
        }
    }

    // sets the paddings regarding the config
    setPaddings(container) {
        let paddingsConfigName;
        if (this.baseUseClass.isMobile()) {
            paddingsConfigName = 'paddings-mobile';
        } else if (this.baseUseClass.isTablet()) {
            paddingsConfigName = 'paddings-tablet';
        } else if (this.baseUseClass.isSmallDesktop()) {
            paddingsConfigName = 'paddings-small-desktop';
        } else if (this.baseUseClass.isDesktop()) {
            paddingsConfigName = 'paddings-desktop';
        }
        // get the config from data attribute
        const configStr = container.data(paddingsConfigName);
        // paddings will be set at the content element
        const contentElem = container.find('.cmp-container--content')
            .first();
        if (configStr && contentElem.length > 0) {
            const configArr = Array.from(configStr);
            let paddingIsSet = false;
            $(configArr)
                .each((index, padding) => {
                    if (padding > -1) {
                        paddingIsSet = true;
                        const paddingSize = `${padding}px`;
                        if (index === 0) {
                            contentElem.css('padding-top', paddingSize);
                        } else if (index === 1) {
                            contentElem.css('padding-right', paddingSize);
                        } else if (index === 2) {
                            contentElem.css('padding-bottom', paddingSize);
                        } else if (index === 3) {
                            contentElem.css('padding-left', paddingSize);
                        }
                    }
                });
            if (!paddingIsSet) {
                contentElem.css('padding-top', '');
                contentElem.css('padding-right', '');
                contentElem.css('padding-bottom', '');
                contentElem.css('padding-left', '');
            }
        }
    }
}

export default Container;
