class HardwaresupportIframe {
    constructor() {
        this.hwsOrigin = 'https://eu-dg.knowmax.ai';
        this.hwsMagentaBasePath = '/hilfe-service/services/hardwaresupport';
        this.iframeHeightAddition = 110;
        this.hwsIframe = document.getElementById('hws-iframe');

        this.magentaOrigin = 'https://www.magenta.at';
        const {hostname} = window.location;
        if (hostname === 'www.t1.magenta.at') {
            this.magentaOrigin = 'https://www.t1.magenta.at';
        } else if (hostname === 'www.x1.magenta.at') {
            this.magentaOrigin = 'https://www.x1.magenta.at';
        }

        const hwsWrapper = document.getElementById('hws-iframe-wrapper');
        if (hwsWrapper) {
            this.isLinkShare = hwsWrapper.getAttribute('data-link-share') === 'true';

            if (this.isLinkShare) {
                const linkInputName = hwsWrapper.getAttribute('data-hws-link-input');
                this.linkInput = document.querySelector(`[name='${linkInputName}']`);
            }
        }
    }

    init() {
        if (!this.hwsIframe) {
            return;
        }

        const path = this.extractPathFromUrl();
        let iframeUrl = `${this.hwsOrigin}/`;
        if (path) {
            iframeUrl += `?path=${path}`;
        }
        this.hwsIframe.setAttribute('src', iframeUrl);

        if (this.linkInput) {
            this.linkInput.value = this.getHwsLink(path);
            this.initResetMailform(this.linkInput);
        }

        window.addEventListener('message', (event) => {
            if (event.data.height || event.data.path) {
                if (event.data.height) {
                    this.hwsIframe.style.height = `${event.data.height + this.iframeHeightAddition}px`;
                }

                if (event.data.path) {
                    this.updateBrowserUrl(event.data.path);
                    if (this.isLinkShare) {
                        if (this.linkInput) {
                            this.linkInput.value = this.getHwsLink(event.data.path);
                        }
                    }
                }
            }
        });

        window.addEventListener('resize', this.receiveHeightFromChild.bind(this));
    }

    initResetMailform(linkInput) {
        const resetButton = document.querySelector("[name='resetHws']");
        if (resetButton) {
            resetButton.addEventListener('click', function() {
                const form = linkInput.closest('form');
                if (form) {
                    const submitBtn = form.querySelector("[name='submit']");
                    submitBtn.removeAttribute('disabled');
                    submitBtn.querySelector(".waiting").style.display = "none";
                    form.querySelector("[name='email']").value = '';
                    form.style.display = "block";
                }
                linkInput.closest('.container').querySelector('.cmp-form-container--success-area').style.display = "none";
            });
        }
    }

    receiveHeightFromChild() {
        const needHeight = 1;
        this.hwsIframe.contentWindow.postMessage({ height: needHeight }, this.hwsOrigin);
    }

    getHwsLink(path = '') {
        return this.magentaOrigin + this.hwsMagentaBasePath + path;
    }

    extractPathFromUrl() {
        const paths = window.location.pathname.split(this.hwsMagentaBasePath);
        return paths[1] || '';
    }

    updateBrowserUrl(path) {
        if (path) {
            const newUrl = this.getHwsLink(path)
            window.history.pushState({ path: newUrl }, '', newUrl);
        }
    }
}

export default HardwaresupportIframe;
