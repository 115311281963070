const applyPolyfills = () => {
    // window.CustomEvent Polyfill
    if (typeof window.CustomEvent !== 'function') {
        window.CustomEvent = function (event, params) {
            params = params || {bubbles: false, cancelable: false, detail: null};
            const evt = document.createEvent('CustomEvent');
            evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
            return evt;
        };
    }

    // CSS :focus-visible polyfill
    if (window.applyFocusVisiblePolyfill != null) {
        window.applyFocusVisiblePolyfill(document);
    }
};

export default applyPolyfills;
