class Carousel {
    constructor(baseUseClass) {
        this.baseUseClass = baseUseClass;
        this.init = this.init.bind(this);
        this.initCarousel = this.initCarousel.bind(this);
        this.destroyCarousel = this.destroyCarousel.bind(this);
        this.checkFullWidth = this.checkFullWidth.bind(this);
        this.colorizeDots = this.colorizeDots.bind(this);
        this.colorizeArrows = this.colorizeArrows.bind(this);
        this.setActiveDotColor = this.setActiveDotColor.bind(this);
    }

    init(elem) {
        const $root = elem || $(document);
        let elements = $root.find('.magat.cmp-carousel > .cmp-carousel__content');
        if (this.baseUseClass.isEditMode() === false || window.self === window.top) {
            this.checkFullWidth(elements);
            this.initCarousel(elements);
        }
        if (window.self !== window.top || this.baseUseClass.isEditMode() === true) {
            $(document).customOn('wcmModeChanged', (e) => {
                elements = $(document).find('.magat.cmp-carousel > .cmp-carousel__content');
                if (e.originalEvent.detail.mode === 'preview') {
                    this.checkFullWidth(elements);
                    this.initCarousel(elements);
                } else if (e.originalEvent.detail.mode === 'edit') {
                    this.checkFullWidth(elements);
                    this.destroyCarousel(elements);
                }
            });
            if (typeof window.Granite !== 'undefined') {
                new window.Granite.author.MessageChannel('cqauthor', window).subscribeRequestMessage('cmp.panelcontainer', (msg) => {
                    this.setActiveItem($(document).find('.magat.cmp-carousel'), msg);
                });
            }
        }
        $(document).customOn('refresh', (event, element) => {
            if (!element) {
                element = document.body;
            }
            const carouselElements = $(element).find('.magat.cmp-carousel > .cmp-carousel__content');
            this.initCarousel($(carouselElements));
        });
    }

    /**
     * Initializes the carousel(s)
     * @param elements all carousel elements
     */
    initCarousel(elements) {
        $(elements).each((index, current) => {
            if ($(current).find('.owl-carousel').length === 0) {
                const options = $(current).data().carouselOptions;
                const color = $(current).data().dotsColor;
                const {arrowsColor} = $(current).data();
                const {arrowsBackground} = $(current).data();

                //create nav. arrow with background circle:
                if(typeof arrowsBackground == 'undefined'){     //default BackgroundColor
                    if(typeof arrowsColor == 'undefined'){      //default ArrowColor
                        options.navText =[
                             '<div class="angle-background";> <span class="magenta-icon-angle-left"> </span> </div>',
                             '<div class="angle-background";> <span class="magenta-icon-angle-right"></span> </div>',
                        ];
                    }
                    else{   //custom ArrowColor
                        options.navText =[
                            '<div class="angle-background";> <span class="magenta-icon-angle-left" style="color:'+ arrowsColor +';"> </span> </div>',
                            '<div class="angle-background";> <span class="magenta-icon-angle-right" style="color:'+ arrowsColor +';"></span> </div>',
                        ];
                    }
                }
                else{   //Custom BackgroundColor
                    if(typeof arrowsColor == 'undefined'){  //default ArrowColor
                        options.navText =[
                            '<div class="angle-background" style="background-color:'+ arrowsBackground +';"> <span class="magenta-icon-angle-left"> </span> </div>',
                            '<div class="angle-background" style="background-color:'+ arrowsBackground +';"> <span class="magenta-icon-angle-right"></span> </div>',
                        ];
                   }
                   else{ //custom ArrowColor
                        options.navText =[
                            '<div class="angle-background" style="background-color:'+ arrowsBackground +';"> <span class="magenta-icon-angle-left" style="color:'+ arrowsColor +';"> </span> </div>',
                            '<div class="angle-background" style="background-color:'+ arrowsBackground +';"> <span class="magenta-icon-angle-right" style="color:'+ arrowsColor +';"></span> </div>',
                        ];
                   }
                }
                const children = $(current).children('.cmp-carousel__item');
                /** Show the hidden carousel items */
                $(current).find('.cmp-carousel__item--hidden').each((i, el) => {
                    $(el).removeClass('cmp-carousel__item--hidden');
                });
                /** Wrap all carousel children in a div */
                const carousel = $(children).wrapAll('<div class="owl-carousel owl-theme"></div>').parent();
                /** Either reset height for each slide or keep height of the greatest height */
                if (options.autoHeight) {
                    $(carousel).addClass('owl-height-auto');
                } else {
                    $(carousel).addClass('owl-height-greatest');
                }
                /** Finally initialize the carousel */
                const waitForOwl = window.setInterval(() => {
                    if (typeof $.fn.owlCarousel === 'function') {
                        window.clearInterval(waitForOwl);
                        $(carousel).owlCarousel(options);
                        /** Color of Navigation Dots */
                        this.colorizeDots(carousel, color);
                        this.colorizeArrows(carousel, arrowsColor);
                    }
                }, 10);
                /** Apply associated events on the carousel */
                if (options.autoplay) {
                    $(current).customOff('mouseenter focusin');
                    $(current).customOn('mouseenter focusin', () => {
                        carousel.trigger('stop.owl.autoplay');
                    });
                    $(current).customOff('mouseleave focusout');
                    $(current).customOn('mouseleave focusout', () => {
                        carousel.trigger('play.owl.autoplay', [options.autoplayTimeout, options.autoplaySpeed]);
                    });
                }
            }
        });
    }

    /**
     * Sets the custom color for the carousel dots
     * @param carousel the carousel element
     * @param color the custom color
     */
    colorizeDots(carousel, color) {
        const that = this;
        const $dots = carousel.find('button.owl-dot');
        $dots.each(function (index, dot) {
            const $dot = $(dot);
            $dot.find('span').css('border', `1px solid ${color}`);
            $dot.hasClass('active')
                ? $dot.find('span').css('background-color', color)
                : $dot.find('span').css('background-color', 'transparent');
            $dot.click(function (e) {
                that.setActiveDotColor(e.currentTarget, color);
            });
            $(carousel).customOff('translated.owl.carousel');
            $(carousel).customOn('translated.owl.carousel', (e) => {
                that.setActiveDotColor($(e.currentTarget).find('button.owl-dot.active'), color);
            });
            $dot.hover(function (e) {
                !$(this).hasClass('active')
                && $(this).find('span').css('background-color', e.type === 'mouseenter' ? color : 'transparent');
            });
        });
    }

    /**
     * Sets the custom color for the carousel arrows
     * @param carousel the carousel element
     * @param color the custom color
     */
    colorizeArrows(carousel, color) {
        const $arrows = carousel.find('.owl-nav button');
        $arrows.each(function (index, arrow) {
            $(arrow).css('color', color);
        });
    }

    /**
     * Sets the custom color of an active carousel dot
     * @param target the active carousel dot
     * @param color the custom color
     */
    setActiveDotColor(target, color) {
        const $target = $(target);
        $target.find('span').css('background-color', color);
        $target.siblings().each(function (index, sibling) {
            $(sibling).find('span').css('background-color', 'transparent');
        });
    }

    /**
     * Destroys the carousel(s)
     * @param elements carousel elements
     */
    destroyCarousel(elements) {
        $(elements).each((index, current) => {
            $(current).find('.owl-carousel').trigger('destroy.owl.carousel');
            $(current).find('.owl-carousel').children().unwrap();
        });
    }

    /**
     * Sets the active element or the carousel for editing
     * @param elements the carousel elements
     * @param message the granite message
     */
    setActiveItem(elements, message) {
        $(elements).each((index, current) => {
            /** modified extract of core component javascript */
            /*eslint-disable */
            if (message.data && message.data.type === 'magat-carousel' && message.data.id === current.getAttribute('data-cmp-panelcontainer-id')) {
                if (message.data.operation === 'navigate') {
                    const index = message.data.index;
                    const items = $(current).find('[data-cmp-hook-carousel="item"]').toArray();

                    if (items) {
                        if (items.length > 1) {
                            for (let i = 0; i < items.length; i++) {
                                if (i === parseInt(index)) {
                                    items[i].classList.add('cmp-carousel__item--active');
                                    items[i].removeAttribute('aria-hidden');
                                } else {
                                    items[i].classList.remove('cmp-carousel__item--active');
                                    items[i].setAttribute('aria-hidden', true);
                                }
                            }
                        } else {
                            // only one item
                            items[0].classList.add('cmp-carousel__item--active');
                        }
                    }
                }
            }
            /* eslint-enable */
        });
    }

    /**
     * Sets the full with of all carousel elements
     * @param elements carousel elements
     */
    checkFullWidth(elements) {
        $(elements).each((index, current) => {
            const childrenFullWidth = $(current).find('.full-width');
            if (childrenFullWidth.toArray().length > 0) {
                $(current).addClass('full-width');
            }
        });
    }
}

export default Carousel;




//auch display flex
//align-items / align-content /