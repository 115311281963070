class Customhtml {
    constructor() {
        this.init = this.init.bind(this);
        this.selectors = {
            placeholder: '#cmp-custom-html--placeholder',
        };
    }

    init() {
        $('.magat.cmp-custom-html').each((index, element) => {
            const childNodes = $(element).children().not(`${this.selectors.placeholder}, CQ`);

            for (let i = 0; i < childNodes.length; i += 1) {
                const height = $(childNodes[i]).height();
                if (height && height > 0) {
                    $(this.selectors.placeholder).remove();
                    return;
                }
            }
        });
    }
}

export default Customhtml;
