class DropDown {
    constructor() {
        this.init = this.init.bind(this);
        this.process = this.process.bind(this);
    }

    init() {
        this.process();
        $(document).customOn('editable-updated', () => {
            this.process();
        });
    }

    process() {
        if ($('.cmp-form-options__field--drop-down').length) {
            $('.cmp-form-options__field--drop-down').SumoSelect();
        }
        $('.cmp-form-options').css('visibility', 'visible');
    }
}

export default DropDown;
